/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-string-refs */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { hexToRgb } from '../../../utils/helper';
import { order as orderData } from '../../../utils/entity_mapping';
import moment from 'moment';

class Timeline extends Component {
  constructor(props) {
    super(props);
    this.toggleTimelineView = this.toggleTimelineView.bind(this);
    this.state = {
      showAll: false,
      order: {},
      percentage: 0,
      progressBarStyle: {},
      animate: false,
      rippleStyle: {},
      isTransitionOff: false
    };
  }

  componentDidMount = () => {
    const nextProps = this.props;
    if (nextProps) {
      const { order } = nextProps.tracking;
      if (order.timeline.length > 0) {
        this.setState({ order });
        this.timelineProgress = setTimeout(() => {
          const interval = 0.8;
          for (var i = 0; i < order.timeline.length; i++) {
            if (i <= order.reached) {
              order.timeline[i].style = {
                backgroundColor: order.timelineColor,
                transition: `all ${interval *
                  (i + 1.2)}s cubic-bezier(.08,.82,.17,1) ${0.4 * i}s`,
                border: `1px solid  ${order.timelineColor}`
              };
              order.timeline[i].vertProgressStyle = {
                backgroundColor: order.timelineColor,
                transition: `all ${interval *
                  (i + 1.2)}s cubic-bezier(.08,.82,.17,1) ${0.4 * i}s`
              };
            }
          }
          const progressBarStyle = {
            width: order.percentage[order.reached] + '%',
            backgroundColor: order.timelineColor,
            transition: `all ${0.3 * (order.reached + 1)}s linear 0s`
            // transition: `all ${0.3 * (order.reached +1)}s linear, -webkit-transform 1s ease-in`
          };
          this.setState({ progressBarStyle, order });
        }, 500);

        this.blink = setTimeout(() => {
          const color = hexToRgb(order.timelineColor);
          this.setState({
            animate: true,
            rippleStyle: {
              backgroundImage: `radial-gradient( circle at 50% 50%, rgba(${color[0]}, ${color[1]}, ${color[2]}, 0), rgba(${color[0]}, ${color[1]}, ${color[2]}, .01) 20%, rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.25) )`
            }
          });
        }, (order.reached + 1) * 750);
      }
    }
    window.addEventListener('resize', this.stopTransitions.bind(this));
  };

  // cssReset = () => {
  //   const {order}  = this.state;
  //   for (let i = 0; i < order.timeline.length; i++) {
  //     order.timeline[i].style = {};
  //   }
  //   this.state ={}
  // }

  /**
   * Remove event listener
   */
  componentWillUnmount = () => {
    clearTimeout(this.timelineProgress);
    clearTimeout(this.blink);
    window.removeEventListener('resize', this.stopTransitions.bind(this));
    this.setState({ order: {} });
  };

  stopTransitions = () => {
    this.setState({ isTransitionOff: true });
  };

  toggleTimelineView() {
    this.setState({ showAll: !this.state.showAll });
  }

  render() {
    const tracking = this.props.tracking;
    const maxHeight = this.props.height;
    let customStyle = {};
    if (maxHeight) {
      customStyle = { maxHeight: maxHeight + 'px' };
      if (tracking.scans.length < 1) {
        customStyle = { height: maxHeight + 'px' };
      }
    }
    const header = tracking.order.timeline.map((item, i) => (
      <li
        key={i}
        className={`${
          tracking.order.reached > i
            ? 'reached'
            : tracking.order.reached === i
            ? 'active'
            : ''
        }`}
      >
        {item.title}
        {i < tracking.order.reached ? (
          <span
            className="vert-progress visible-sm visible-xs"
            style={Object.assign(
              this.state.isTransitionOff ? { transitionProperty: 'none' } : {},
              this.state.order.timeline
                ? this.state.order.timeline[i].vertProgressStyle
                : {}
            )}
          />
        ) : null}
        <span
          id={i}
          ref={item.status}
          data-id={tracking.order.timeline.length - 1}
          className={`oval ${
            tracking.order.reached > i
              ? 'reached'
              : tracking.order.reached === i
              ? 'active'
              : ''
          }`}
          style={Object.assign(
            this.state.isTransitionOff ? { transitionProperty: 'none' } : {},
            this.state.order.timeline ? this.state.order.timeline[i].style : {}
          )}
        >
          {tracking.order.reached === i &&
          tracking.order.timeline.length - 1 !== i &&
          this.state.animate ? (
            <span className="ripple" style={this.state.rippleStyle}>
              <span
                className="ripple"
                style={Object.assign(
                  { animationDelay: '2s' },
                  this.state.rippleStyle
                )}
              >
                <span
                  className="ripple"
                  style={Object.assign(
                    { animationDelay: '3s' },
                    this.state.rippleStyle
                  )}
                />
              </span>
            </span>
          ) : null}
        </span>
        {/* <span className={`date ${tracking.order.reached > i ?
          'reached': tracking.order.reached === i ? 'active' :
        ''}`}>{item.day} {item.month}</span> */}
      </li>
    ));
    const statuses =
      tracking.scans.length > 0
        ? tracking.scans.map((item, i) => (
            <div key={i} className="row mrg-0 text-left journery-rows">
              <div className="col-md-3 col-xs-10 col-md-offset-1 xs-text-gray">
                {moment(item.timestamp).format('DD MMM YYYY ')}
                <span className="visible-sm-inline-block visible-xs-inline-block">
                  ;&nbsp;
                </span>
                <span className="visible-sm-inline-block visible-xs-inline-block">
                  {item.time}
                </span>
                {item.location ? (
                  <span className="visible-sm visible-xs text-capitalize">
                    {item.location.toLowerCase()}
                  </span>
                ) : null}
              </div>
              <div className="col-md-4 col-xs-12 hidden-sm hidden-xs">
                {item.time}
              </div>
              <div className="col-md-5 col-xs-12 hidden-sm hidden-xs text-capitalize">
                {item.location ? item.location.toLowerCase() : null}
              </div>
              <div className="col-md-10 col-xs-14">
                {item.remark}{' '}
                {orderData.returned.indexOf(item.code) > -1 && i === 0 ? (
                  <strong>(RTO)</strong>
                ) : null}
              </div>
            </div>
          ))
        : [];

    return (
      <div className="card pd-0">
        <div className="header pd-15">
          <ul className="timeline">{header}</ul>
          <div className="progress">
            <div
              ref="progressBar"
              className="progress-bar"
              style={this.state.progressBarStyle}
            />
          </div>
        </div>
        <hr
          className={`full-width mrg-top-0 mrg-btm-0 ${
            tracking.scans.length === 0 ? 'hidden-xs' : ''
          }`}
        />
        {tracking.scans.length > 0 ? (
          <Fragment>
            <div className="row mrg-0 text-left">
              <div className="col-md-24 col-xs-24 mrg-top-20 xs-mrg-top-10 mrg-btm-10 journery-title">
                <b>Detailed journey</b>
              </div>
            </div>
            <hr
              className={`full-width mrg-top-0 mrg-btm-0 ${
                tracking.scans.length === 0 ? 'visible-xs' : ''
              }`}
            />
            <div className="row mrg-0 text-left journery-header hidden-xs">
              <div className="col-md-3 hidden-sm col-xs-12 col-md-offset-1">
                Date
              </div>
              <div className="col-md-4 hidden-sm col-xs-12">Time</div>
              <div className="col-md-5 hidden-sm col-xs-12">Location</div>
              <div className="col-md-10 hidden-sm col-xs-12">Remarks</div>
            </div>
            <div className="journery-rows-wrapper">{statuses}</div>
          </Fragment>
        ) : (
          <div className="pd-15">
            <div className="no-status-card" style={customStyle}>
              <div className="row status-card">
                <div className="col-md-24 col-xs-24">
                  {tracking?.latestStatus?.code === 8 ? (
                    <p className="mrg-top-10 mrg-btm-10 text-left">
                      Carrier scans are not currently available.
                    </p>
                  ) : (
                    <p className="mrg-top-10 mrg-btm-10 text-left">
                      Ready to Ship!
                      <br />
                      Tracking details will be available shortly.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

Timeline.propTypes = {
  tracking: PropTypes.object,
  height: PropTypes.number
};

export default Timeline;
