/* eslint-disable react/no-string-refs */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import ContentLoader from 'react-content-loader';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import ReactGA from 'react-ga';
import { openUrl, ga4ClickEvent } from '../utils/helper';

// let activeSlide = 0;

class Banner extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   activeSlide: 0
    // };
    this.linkButton = this.linkButton.bind(this);
  }

  linkButton(e, item) {
    const { page, user, tracking } = this.props;
    const { webview } = user;
    e.preventDefault();
    const { redirect_url, title } = item;
    ga4ClickEvent({
      category: 'Tile',
      action: redirect_url,
      label: title,
      props: { page, user, tracking }
    });
    ReactGA.event({
      category: 'Tile',
      action: redirect_url,
      label: title
    });
    if (webview) {
      window.location = redirect_url;
    } else {
      openUrl(redirect_url, '_blank');
      // window.open(redirect_url, '_blank');
    }
  }

  render() {
    const settings = {
      infinite: true,
      autoplaySpeed: 3000,
      autoplay: true,
      margin: 0,
      centerMode: true,
      centerPadding: '0px',
      arrows: true,
      slidesToScroll: 1,
      swipeToSlide: false,
      dots: false,
      fade: true,
      speed: 500,
      slidesToShow: 1,
      verticalSwiping: false,
      draggable: false,
      swipe: false
      // beforeChange: (current, next) => {
      //   activeSlide = next;
      // }
      // swipe: false,
      // slidesToScroll: 1
    };

    const { isFetching, handleImageLoad, tracking } = this.props;
    let banner = [];
    if (tracking.theme.meta_data && tracking.theme.meta_data.banners) {
      banner = tracking.theme.meta_data.banners.filter(
        item => item.type === 'right'
      );
    }

    const slides = banner.map((item, i) => {
      const herbalifeTitles = {
        'How to Hydrate Fast and What Liquids Count as Water Intake?':
          'Cara Menghidrasi dengan Cepat dan Cairan Apa Saja yang Dihitung sebagai Asupan Air',
        '10 Heart-Healthy Foods and Snacks to Add to Your Grocery List':
          '10 Makanan dan Camilan yang mendukung kesehatan jantung'
      };
      const isHerbalifeId =
        (this.props.subdomain === 'herbalife-id' ||
          this.props.subdomain === 'herbalife-test') &&
        this.props.lang === 'id';
      const title =
        isHerbalifeId && herbalifeTitles[item.title]
          ? herbalifeTitles[item.title]
          : item.title;

      return (
        <div key={i}>
          <div className="banner">
            <img
              src={item.url}
              title={title}
              alt={isHerbalifeId ? title : item.alt}
              onClick={e => this.linkButton(e, item)}
              onLoad={handleImageLoad}
              style={{ maxHeight: 270 }}
            />
            {title ? (
              <div
                className="title"
                role="button"
                tabIndex="0"
                onClick={e => this.linkButton(e, item)}
              >
                {title}
              </div>
            ) : null}
          </div>
        </div>
      );
    });

    return (
      <div
        ref="ads"
        id="creativeContainer"
        className={`col-md-9 col-xs-24 cm-right mrg-top-20 xs-mrg-top-10 ${
          banner.length > 0 ? '' : 'hide'
        }`}
      >
        <div className={`card ads min-ht-auto`}>
          {isFetching ? (
            <ContentLoader height={150} width={200}>
              <rect x="0" y="0" rx="0" ry="0" width="200" height="150" />
            </ContentLoader>
          ) : banner.length > 0 ? (
            <Slider {...settings}>{slides}</Slider>
          ) : null}
        </div>
      </div>
    );
  }
}

// export default Banner;

Banner.propTypes = {
  user: PropTypes.object,
  tracking: PropTypes.object,
  page: PropTypes.object,
  banners: PropTypes.array,
  handleImageLoad: PropTypes.func,
  isFetching: PropTypes.bool,
  subdomain: PropTypes.string,
  lang: PropTypes.string
};

const mapStateToProps = state => ({
  user: state.user,
  tracking: state.tracking,
  page: state.page
});

export default connect(mapStateToProps, null)(Banner);
