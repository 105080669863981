const layout = {
  default: {
    orderPlaced: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'payment' },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    readyToShip: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'payment' },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    dispatched: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'payment' },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    inTransit: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'payment' },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    lost: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'payment' },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    damaged: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'payment' },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    outForDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'rating' },
      { block: 'payment' },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    failedDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'payment' },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'concernCard' },
      { block: 'bottom' }
    ],
    delivered: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'payment' },
      { block: 'rating' },
      { block: 'ads' },
      { block: 'timeline' },
      { block: 'bottom' }
    ],
    returned: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'payment' },
      { block: 'rating' },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    cancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'rating' },
      { block: 'payment' },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    pickupRequested: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'payment' },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    outForPickup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'payment' },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    pickupFailed: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'payment' },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    pickedup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'payment' },
      { block: 'rating' },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    pickupCancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'rating' },
      { block: 'payment' },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    default: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'payment' },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ]
  },
  vanheusenindia: {
    orderPlaced: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    readyToShip: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    dispatched: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    inTransit: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    lost: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    damaged: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    outForDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    failedDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'concernCard' }
    ],
    delivered: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'rating' }
    ],
    returned: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    cancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickupRequested: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    outForPickup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickupFailed: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickedup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickupCancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    default: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ]
  },
  ritukumar: {
    orderPlaced: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    readyToShip: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    dispatched: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    inTransit: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    lost: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    damaged: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    outForDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    failedDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'concernCard' }
    ],
    delivered: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'rating' }
    ],
    returned: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    cancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickupRequested: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    outForPickup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickupFailed: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickedup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickupCancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    default: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ]
  },
  'herbalife-apac': {
    orderPlaced: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    readyToShip: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    dispatched: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    inTransit: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    lost: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    damaged: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    outForDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    failedDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'concernCard' }
    ],
    delivered: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'rating' }
    ],
    returned: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    cancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickupRequested: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    outForPickup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickupFailed: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickedup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickupCancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    default: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ]
  },
  thesouledstore: {
    orderPlaced: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    readyToShip: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    dispatched: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    inTransit: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    lost: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    damaged: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    outForDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    failedDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'concernCard' }
    ],
    delivered: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    returned: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    cancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickupRequested: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    outForPickup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickupFailed: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickedup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickupCancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    default: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ]
  },
  superbottoms: {
    orderPlaced: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    readyToShip: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    dispatched: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    inTransit: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    lost: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    damaged: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    outForDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    failedDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'concernCard' },
      { block: 'bottom' }
    ],
    delivered: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    returned: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    cancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    pickupRequested: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    outForPickup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    pickupFailed: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    pickedup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    pickupCancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    default: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ]
  },
  thephylife: {
    orderPlaced: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    readyToShip: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    dispatched: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    inTransit: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    lost: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    damaged: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    outForDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    failedDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'concernCard' }
    ],
    delivered: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'ads' },
      { block: 'timeline' },
      { block: 'rating' }
    ],
    returned: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    cancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickupRequested: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    outForPickup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickupFailed: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickedup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickupCancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    default: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ]
  },
  plumgoodness: {
    orderPlaced: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    readyToShip: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    dispatched: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    inTransit: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    lost: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    damaged: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    outForDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    failedDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'concernCard' }
    ],
    delivered: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'ads' },
      { block: 'timeline' },
      { block: 'rating' }
    ],
    returned: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    cancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickupRequested: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    outForPickup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickupFailed: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickedup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickupCancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    default: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ]
  },
  cbazaar: {
    orderPlaced: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    readyToShip: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    dispatched: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    inTransit: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    lost: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    damaged: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    outForDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    failedDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'concernCard' }
    ],
    delivered: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'ads' },
      { block: 'timeline' },
      { block: 'rating' }
    ],
    returned: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    cancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickupRequested: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    outForPickup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickupFailed: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickedup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickupCancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    default: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ]
  },
  '1mg': {
    orderPlaced: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    readyToShip: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    dispatched: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    inTransit: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    lost: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    damaged: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    outForDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    failedDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'concernCard' },
      { block: 'bottom' }
    ],
    delivered: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'rating' },
      { block: 'bottom' }
    ],
    returned: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    cancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    pickupRequested: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    outForPickup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    pickupFailed: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    pickedup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    pickupCancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    default: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ]
  },
  '1mg-test': {
    orderPlaced: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    readyToShip: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    dispatched: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    inTransit: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    lost: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    damaged: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    outForDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    failedDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'concernCard' },
      { block: 'bottom' }
    ],
    delivered: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'rating' },
      { block: 'bottom' }
    ],
    returned: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    cancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    pickupRequested: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    outForPickup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    pickupFailed: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    pickedup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    pickupCancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    default: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ]
  },
  stylishop: {
    orderPlaced: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    readyToShip: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    dispatched: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    inTransit: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    lost: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    damaged: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    outForDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    failedDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'concernCard' }
    ],
    delivered: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    returned: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    cancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickupRequested: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    outForPickup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickupFailed: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickedup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    pickupCancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ],
    default: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' }
    ]
  },
  damensch: {
    orderPlaced: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    readyToShip: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    dispatched: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    inTransit: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    lost: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    damaged: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    outForDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    failedDelivery: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
      // { block: 'concernCard' }
    ],
    delivered: isMobile =>
      isMobile
        ? [
            { block: 'orderInfo', inMobile: 1 },
            { block: 'timeline' },
            { block: 'rating' },
            { block: 'ads' },
            { block: 'bottom' }
          ]
        : [
            { block: 'orderInfo', inMobile: 1 },
            { block: 'timeline' },
            { block: 'ads' },
            { block: 'rating' },
            { block: 'bottom' }
          ],
    returned: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    cancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    pickupRequested: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    outForPickup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    pickupFailed: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    pickedup: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    pickupCancelled: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ],
    default: [
      { block: 'orderInfo', inMobile: 1 },
      { block: 'timeline' },
      { block: 'ads' },
      { block: 'bottom' }
    ]
  }
};

export default layout;
