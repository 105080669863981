/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-string-refs */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hexToRgb } from '../utils/helper';
import { order as orderData } from '../utils/entity_mapping';
import { strings } from '../utils/localizedStrings';

class Timeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAll:
        !props.tracking.theme.meta_data.ads_enabled &&
        (!props.tracking.config.isRating ||
          props.tracking.latestStatus.code !== 8 ||
          props.tracking.config.isRVP),
      order: {},
      percentage: 0,
      progressBarStyle: {},
      animate: false,
      rippleStyle: {},
      customStyle:
        !props.tracking.theme.meta_data.ads_enabled &&
        props.user.device !== 'mobile' &&
        (!props.tracking.config.isRating ||
          props.tracking.latestStatus.code !== 8 ||
          props.tracking.config.isRVP)
          ? { maxHeight: '40vh' }
          : { maxHeight: this.props?.fullHeight ? '100%' : '75px' }
    };
  }

  componentWillMount() {
    const nextProps = this.props;
    if (nextProps) {
      const { order } = nextProps.tracking;
      if (order.timeline.length > 0) {
        this.setState({ order });
        this.timelineProgress = setTimeout(() => {
          const interval = 0.8;
          for (var i = 0; i < order.timeline.length; i++) {
            if (i <= order.reached) {
              order.timeline[i].style = {
                backgroundColor: order.timelineColor,
                transition: `all ${interval *
                  (i + 1.2)}s cubic-bezier(.08,.82,.17,1) ${0.4 * i}s`,
                border: `1px solid  ${order.timelineColor}`
              };
            }
          }
          const progressBarStyle = {
            width: order.percentage[order.reached] + '%',
            backgroundColor: order.timelineColor,
            transition: `all ${0.3 * (order.reached + 1)}s linear 0s`
            // transition: `all ${0.3 * (order.reached +1)}s linear, -webkit-transform 1s ease-in`
          };
          this.setState({ progressBarStyle, order });
        }, 500);

        this.blink = setTimeout(() => {
          const color = hexToRgb(order.timelineColor);
          this.setState({
            animate: true,
            rippleStyle: {
              backgroundImage: `radial-gradient( circle at 50% 50%, rgba(${color[0]}, ${color[1]}, ${color[2]}, 0), rgba(${color[0]}, ${color[1]}, ${color[2]}, .01) 20%, rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.25) )`
            }
          });
        }, (order.reached + 1) * 750);
      }
    }
  }

  componentDidMount = () => {
    const { user } = this.props;
    let { customStyle, showAll } = this.state;
    window.addEventListener('resize', this.updateDimensions.bind(this));
    setTimeout(() => this.updateDimensions(), 1000);
    const rect = this.refs.timelineViewBtn.getBoundingClientRect();
    const scrollTop = document.documentElement.clientTop;
    if (
      this.props.user.subdomain === 'damensch' &&
      this.props.user.device === 'mobile'
    ) {
      const [targetNode] = document.getElementsByClassName('meta-card');
      const observer = new ResizeObserver(this.updateDimensions);
      observer.observe(targetNode);
      this.toggleTimelineView(this.refs.timelineViewBtn);
    }
    if (rect && user.subdomain === 'stylishop') {
      if (this.props.user.device !== 'mobile') {
        customStyle = { maxHeight: `${this.props.height || 200}px` };
      } else if (!showAll) {
        customStyle = {
          maxHeight:
            window.innerHeight +
            75 -
            (rect.top + scrollTop + this.refs.timelineViewBtn.offsetHeight) +
            'px'
        };
      } else {
        customStyle = { maxHeight: this.props?.fullHeight ? '100%' : '75px' };
      }
      this.setState({ showAll: true, customStyle });
    } else if (this.props.user.device === 'mobile' && showAll) {
      customStyle =
        !this.props.tracking.theme.meta_data.ads_enabled &&
        (!this.props.tracking.config.isRating ||
          this.props.tracking.latestStatus.code !== 8 ||
          this.props.tracking.config.isRVP)
          ? {
              maxHeight:
                window.innerHeight -
                (rect.top +
                  scrollTop +
                  this.refs.timelineViewBtn.offsetHeight) +
                'px'
            }
          : { maxHeight: '75px' };
      this.setState({ showAll: true, customStyle });
    }
  };

  componentWillReceiveProps = () => {
    this.updateDimensions();
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
    clearTimeout(this.timelineProgress);
    clearTimeout(this.blink);
  };

  updateDimensions = () => {
    if (this.props.user.device !== 'mobile') {
      const elem = document.getElementById('creativeContainer');
      if (elem) {
        let maxHeight = elem.clientHeight;
        if (maxHeight && window.innerWidth > 768) {
          maxHeight = maxHeight - 123;
        } else if (maxHeight && window.innerWidth < 769) {
          maxHeight = 75;
        }
        this.setState({ customStyle: { maxHeight } });
      }
    } else if (
      this.props.user.subdomain === 'stylishop' &&
      this.props.user.device === 'mobile'
    ) {
      const customStyle = { maxHeight: `100%`, overflowY: 'unset' };
      this.setState({ customStyle });
    } else if (
      this.props.user.subdomain === 'damensch' &&
      this.props.user.device === 'mobile'
    ) {
      const rect = this.refs.timelineViewBtn.getBoundingClientRect();
      this.setState({
        customStyle: {
          maxHeight: `calc(${
            this.state.customStyle.maxHeight
          } + ${window.innerHeight - rect.top - 40}px)`
        }
      });
    }
  };

  toggleTimelineView = e => {
    let { showAll, customStyle } = this.state;
    const rect = e.getBoundingClientRect();
    const scrollTop = document.documentElement.clientTop;
    if (this.props.user.device !== 'mobile') {
      customStyle = { maxHeight: `${this.props.height}px` };
    } else if (!showAll) {
      let offsetValue = this.props.productsEnabled ? '-12' : '75';
      customStyle = {
        maxHeight:
          window.innerHeight +
          parseInt(offsetValue) -
          (rect.top + scrollTop + e.offsetHeight) +
          'px'
      };
    } else {
      customStyle = { maxHeight: '75px' };
    }
    this.setState({ showAll: !showAll, customStyle });
  };

  render() {
    const { tracking, user } = this.props;
    const { lang } = user;
    const { udfKeys } = tracking.theme;
    udfKeys.titleTextClassName = udfKeys.titleTextClassName ?? '';
    const maxHeight = this.props.height;
    let customStyle = {};
    if (maxHeight) {
      customStyle = { maxHeight: maxHeight + 'px' };
      if (tracking.scans.length < 1) {
        customStyle = { height: maxHeight + 'px' };
      }
    }

    const header = tracking.order.timeline.map((item, i) => (
      <li key={i}>
        {user.subdomain !== 'damensch' ? (
          <span className={`${udfKeys.titleTextClassName}`}>{item.title}</span>
        ) : null}
        <span
          id={i}
          ref={item.status}
          data-id={tracking.order.timeline.length - 1}
          className={`oval ${
            tracking.order.reached >= i
              ? 'reached'
              : tracking.order.reached === i
              ? 'active'
              : ''
          }`}
          style={
            this.state.order.timeline && user.subdomain !== 'damensch'
              ? this.state.order.timeline[i].style
              : {}
          }
        >
          {tracking.order.reached === i &&
          tracking.order.timeline.length - 1 !== i &&
          this.state.animate &&
          user.subdomain !== 'damensch' ? (
            <span className="ripple" style={this.state.rippleStyle}>
              <span
                className="ripple"
                style={Object.assign(
                  { animationDelay: '2s' },
                  this.state.rippleStyle
                )}
              >
                <span
                  className="ripple"
                  style={Object.assign(
                    { animationDelay: '3s' },
                    this.state.rippleStyle
                  )}
                />
              </span>
            </span>
          ) : null}
          {user.subdomain === 'damensch' ? (
            <span className="mrg-top-20 li-ht-16">{item.title}</span>
          ) : null}
        </span>
        {/* <span className={`date ${tracking.order.reached > i ?
          'reached': tracking.order.reached === i ? 'active' :
        ''}`}>{item.day} {item.month}</span> */}
      </li>
    ));
    const statuses =
      tracking.scans.length > 0
        ? tracking.scans.map((item, i) => (
            <li
              key={i}
              className={`${i === 0 ? 'active' : ''} ${
                tracking.scans.length <= 1 ? 'one-item' : ''
              }`}
            >
              <div className="row status-card">
                <div className="col-md-8 col-xs-24">
                  <p>
                    <span className="text">
                      <strong className={`${udfKeys.titleTextClassName}`}>
                        {strings[lang].location}:{' '}
                      </strong>{' '}
                      {item.location}
                    </span>
                  </p>
                </div>
                <div className="col-md-4 col-xs-24">
                  <p>
                    <span className="text">
                      <strong className={`${udfKeys.titleTextClassName}`}>
                        {strings[lang].time}:{' '}
                      </strong>{' '}
                      {item.time}
                    </span>
                  </p>
                </div>
                <div className="col-md-12 col-xs-24">
                  <p>
                    <strong className={`${udfKeys.titleTextClassName}`}>
                      {strings[lang].remark}:{' '}
                    </strong>{' '}
                    {item.remark}{' '}
                    {orderData.returned.indexOf(item.code) > -1 && i === 0 ? (
                      <strong>(RTO)</strong>
                    ) : null}
                  </p>
                </div>
              </div>
              <span className={`number`}>
                <span>
                  {item.day} <label className="fs-14">{item.month}</label>
                </span>
              </span>
            </li>
          ))
        : [];

    return (
      <div className={`card pd-0 timeline-card`}>
        <div className="header pd-15">
          <ul className="timeline">{header}</ul>
          <div className={`progress`}>
            <div
              ref="progressBar"
              className={`progress-bar`}
              style={this.state.progressBarStyle}
            />
          </div>
        </div>
        {(user.device === 'desktop' ||
          this.state.showAll ||
          !this.props.productsEnabled) && (
          <>
            <hr
              className={`full-width mrg-top-0 mrg-btm-0 ${
                tracking.scans.length === 0 ? 'hidden-xs' : ''
              }`}
            />
            {tracking.scans.length > 0 ? (
              <div
                className={`vertical-timeline pd-15 xs-pd-10 ${
                  tracking.scans.length < 2
                    ? ''
                    : user.subdomain !== 'stylishop'
                    ? 'xs-pd-btm-0'
                    : ''
                }`}
              >
                <ul
                  className={`${
                    tracking.scans.length > 1
                      ? `scrollbar ${
                          this.state.showAll ? '' : 'xs-overflow-y-hidden'
                        }`
                      : ''
                  }`}
                  style={this.state.customStyle}
                >
                  {statuses}
                </ul>
              </div>
            ) : (
              <div className="pd-15">
                <div className="no-status-card" style={customStyle}>
                  <div className="row status-card">
                    <div className="col-md-24 col-xs-24">
                      {tracking?.latestStatus?.code === 8 ? (
                        <p className="mrg-top-10 text-left">
                          Carrier scans are not currently available.
                        </p>
                      ) : (
                        <p className="mrg-top-10 mrg-btm-10 text-left">
                          {strings[lang].noScan1}
                          <br />
                          {strings[lang].noScan2}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        <div
          className={`footer ${
            user.subdomain === 'stylishop' ? 'hide' : 'visible-xs'
          } ${
            this.props.productsEnabled && tracking.scans.length < 1
              ? ''
              : !this.props.productsEnabled && tracking.scans.length < 2
              ? 'hidden-xs'
              : ''
          }`}
          role="button"
          tabIndex="0"
          onClick={e => this.toggleTimelineView(e.target)}
          ref="timelineViewBtn"
        >
          {this.state.showAll
            ? strings[lang].viewSummary
            : strings[lang].viewDetail}
        </div>
      </div>
    );
  }
}

Timeline.propTypes = {
  tracking: PropTypes.object,
  height: PropTypes.number,
  user: PropTypes.object,
  productsEnabled: PropTypes.bool,
  fullHeight: PropTypes.string
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps, null)(Timeline);
