/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactGA from 'react-ga';
import toast from 'react-hot-toast';
import dompurify from 'dompurify';
import { nprClickEventDescription } from '../../../utils/helper';

import {
  calendarDates,
  nykaaCustomActions
} from '../../../utils/entity_mapping';
import { postNprConcernHandler } from '../../../service';
import DatePicker from 'react-datepicker';
import { strings } from '../../../utils/localizedStrings';

const Issue = ({
  user,
  history,
  npr,
  page,
  tracking,
  customerFeed,
  security_key
}) => {
  const [state, setState] = useState({
    form: 0,
    screen: 1,
    customerFeed: {
      ...customerFeed,
      is_rejected: customerFeed.is_rejected || null,
      comment: customerFeed.comment || '',
      address: customerFeed.address || ''
    },
    isAltNumber: false,
    isSubmitting: false,
    dateMeta: [],
    preferred_date: null,
    isUserDate: false,
    isRejected: null,
    security_key: security_key,
    goBack: {
      form: 0,
      screen: 1,
      preferred_date: null
    }
  });

  useEffect(() => {
    if (npr?.ops.issue_id) {
      setState({ ...state, customerFeed: npr.customer });
    }
    if (!tracking.config.isRVP) {
      goBack();
    }
    const { ndr_reattempt_day_limit } = tracking.theme.meta_data;
    // CalendarDates skipping the first day of the calendar, adding 1 more day to the date iterator explicitly
    const dateMeta = calendarDates({
      itr: ndr_reattempt_day_limit + 1,
      subdomain: user.subdomain
    });
    if (customerFeed.issue_id) {
      if (customerFeed.issue_id === 2) {
        state.customerFeed.is_rejected = true;
      }
      state.screen = 4;
      state.goBack.screen = 4;
      state.goBack.preferred_date = npr.customer.preferred_date;
    }
    setState({ ...state, dateMeta });
  }, []);

  const goBack = () => {
    const { routePrefix } = user;
    history.push(
      `${routePrefix ? `/${routePrefix}` : ''}/${history.location.search}`
    );
  };

  const handleClick = (e, no) => {
    let { form, isRejected, isAltNumber, customerFeed, preferred_date } = state;
    if (no === 3) {
      isRejected = isAltNumber = null;
      customerFeed.preferred_date = preferred_date = null;
    }
    // 99 is for go back to original screen/form
    setState({
      ...state,
      screen: no === 99 ? state.goBack.screen : no,
      form: no === 99 ? state.goBack.form : no === 1 || no === 2 ? 0 : form,
      isRejected,
      isAltNumber,
      customerFeed: {
        ...customerFeed,
        preferred_date:
          no === 99 ? state.goBack.preferred_date : customerFeed.preferred_date
      },
      preferred_date
    });
    if (no === 2) {
      ReactGA.event({
        category: 'CaptureClick',
        action: '0',
        label: nprClickEventDescription(0)
      });
    }
    e.preventDefault();
  };

  const handleRadio = (_e, i) => {
    setState({ ...state, form: i });
    ReactGA.event({
      category: 'CaptureClick',
      action: i.toString(),
      label: nprClickEventDescription(i)
    });
  };

  const dateToggle = e => {
    state.customerFeed.preferred_date = e.target.id;
    setState({
      ...state,
      preferred_date: null,
      isUserDate: true
    });
  };

  const onCalendarChange = date => {
    state.customerFeed.preferred_date = date
      ? moment(new Date(date)).format('YYYY-MM-DD')
      : '';
    setState({
      ...state,
      preferred_date: date ? date : '',
      isUserDate: true
    });
  };

  const changeValue = e => {
    state.customerFeed[e.target.name] = e.target.value;
    setState({ ...state });
  };

  const alternateNumber = e => {
    let { customerFeed, isAltNumber } = state;
    if (e) {
      isAltNumber = true;
      customerFeed.phone_number = '';
    } else {
      isAltNumber = false;
      customerFeed.phone_number = tracking.customer.phone;
    }
    setState({ ...state, customerFeed, isAltNumber });
  };

  const askForPickup = e => {
    let { customerFeed, isRejected } = state;
    customerFeed.is_rejected = isRejected = e;
    setState({ ...state, customerFeed, isRejected });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const phonePattern = new RegExp(/^\d{10}$/);
    const datePattern = new RegExp(/[0-9]{2}[-][0-9]{2}[-][0-9]{4}$/);
    const datePattern2 = new RegExp(/[0-9]{4}[-][0-9]{2}[-][0-9]{2}$/);
    const pincodePattern = new RegExp(/^\d{6}$/);
    const { lang } = user;
    const { customerFeed, form, screen, isAltNumber } = state;
    let query = {};

    if (form === 5 || (form === 0 && npr.ops.issue_id === 5)) {
      if (
        (!datePattern.test(customerFeed.preferred_date) &&
          !datePattern2.test(customerFeed.preferred_date)) ||
        !state.isUserDate
      ) {
        return toast.error(strings[lang].dateErr);
      }
      customerFeed.issue_id = 5;
      query = (({ issue_id, comment, preferred_date }) => ({
        issue_id,
        comment,
        preferred_date
      }))(customerFeed);
    } else if (form === 7 || (form === 0 && npr.ops.issue_id === 7)) {
      customerFeed.issue_id = 7;
      if (
        // customerFeed?.capture?.address &&
        customerFeed.address === null ||
        customerFeed.address === '' ||
        customerFeed?.address?.trim().length < 10
      ) {
        return toast.error(strings[lang].addressErr);
      }
      if (
        // customerFeed?.capture?.drop_pincode &&
        !pincodePattern.test(customerFeed.drop_pincode) ||
        customerFeed.drop_pincode?.trim().length < 6
      ) {
        return toast.error(strings[lang].pincodeErr);
      }
      if (
        // customerFeed?.capture?.landmark &&
        customerFeed.landmark === null ||
        customerFeed.landmark === ''
      ) {
        return toast.error(strings[lang].landmarkErr);
      }
      if (
        // customerFeed?.capture?.landmark &&
        customerFeed?.landmark?.trim().length < 5
      ) {
        return toast.error(strings[lang].provideLandmark);
      }
      if (
        (!datePattern.test(customerFeed.preferred_date) &&
          !datePattern2.test(customerFeed.preferred_date)) ||
        !state.isUserDate
      ) {
        return toast.error(strings[lang].dateErr);
      }
      query = (({
        issue_id,
        address,
        drop_pincode,
        landmark,
        preferred_date
      }) => ({ issue_id, address, drop_pincode, landmark, preferred_date }))(
        customerFeed
      );
    } else if (form === 6 || (form === 0 && npr.ops.issue_id === 6)) {
      if (
        (!datePattern.test(customerFeed.preferred_date) &&
          !datePattern2.test(customerFeed.preferred_date)) ||
        !state.isUserDate
      ) {
        return toast.error(strings[lang].dateErr);
      }
      customerFeed.issue_id = 6;
      query = (({ issue_id, preferred_date }) => ({
        issue_id,
        preferred_date
      }))(customerFeed);
    } else if (form === 1 || (form === 0 && npr.ops.issue_id === 1)) {
      if (
        isAltNumber === null
        //  && customerFeed?.capture?.phone_number
      ) {
        return toast.error(strings[lang].chooseOption);
      }
      if (
        isAltNumber &&
        // customerFeed?.capture?.phone_number &&
        customerFeed.phone_number !== null &&
        customerFeed.phone_number !== ''
      ) {
        const firstChar = Number(customerFeed.phone_number.charAt(0));
        if (firstChar < 6) {
          return toast.error(strings[lang].phoneErr);
        }
      }
      if (
        isAltNumber &&
        // customerFeed?.capture?.phone_number &&
        !phonePattern.test(customerFeed.phone_number)
      ) {
        return toast.error(strings[lang].phoneErr);
      }
      if (
        (!datePattern.test(customerFeed.preferred_date) &&
          !datePattern2.test(customerFeed.preferred_date)) ||
        !state.isUserDate
      ) {
        return toast.error(strings[lang].dateErr);
      }
      customerFeed.issue_id = 1;
      query = (({ issue_id, phone_number, preferred_date }) => ({
        issue_id,
        phone_number,
        preferred_date
      }))(customerFeed);
    } else if (
      form === 3 ||
      (form === 0 && npr.ops.issue_id === 3) ||
      form === 4 ||
      (form === 0 && npr.ops.issue_id === 4)
    ) {
      if (
        (!datePattern.test(customerFeed.preferred_date) &&
          !datePattern2.test(customerFeed.preferred_date)) ||
        !state.isUserDate
      ) {
        return toast.error(strings[lang].dateErr);
      }
      if (npr.ops.issue_id && form !== 3) {
        customerFeed.issue_id = npr.ops.issue_id;
      } else {
        customerFeed.issue_id = 3;
      }
      query = (({ issue_id, comment, preferred_date }) => ({
        issue_id,
        comment,
        preferred_date
      }))(customerFeed);
    } else if (form === 2 || (form === 0 && npr.ops.issue_id === 2)) {
      if (state.isRejected === null) {
        return toast.error(strings[lang].chooseOption);
      }
      if (state.isRejected === false) {
        if (
          (!datePattern.test(customerFeed.preferred_date) &&
            !datePattern2.test(customerFeed.preferred_date)) ||
          !state.isUserDate
        ) {
          return toast.error(strings[lang].dateErr);
        }
      }
      customerFeed.issue_id = 2;
      if (state.isRejected === true) {
        query = (({ issue_id, is_rejected, comment }) => ({
          issue_id,
          is_rejected,
          comment
        }))(customerFeed);
      } else {
        query = (({ issue_id, is_rejected, comment, preferred_date }) => ({
          issue_id,
          is_rejected,
          comment,
          preferred_date
        }))(customerFeed);
      }
    }
    if (screen === 1) {
      query.intent = '';
    } else {
      query.intent = nprClickEventDescription(query.issue_id);
    }
    if (page.source) {
      query.feedback_source = page.source;
    }
    if (query.preferred_date) {
      query.preferred_date = moment(query.preferred_date).format('YYYY-MM-DD');
    }
    setState({ ...state, isSubmitting: true });
    const toastId = toast.loading(strings[lang].actionInProgress);
    query.security_key = state.security_key;

    postNprConcernHandler(
      tracking.trackingId,
      tracking.config.hasRaisedIssue ?? false,
      query
    ).then(res => {
      if (res.meta.success) {
        toast.success(strings[lang].submittedConcern(tracking.courier.name), {
          id: toastId
        });
        setState({
          ...state,
          isSubmitting: false,
          screen: 4
        });
        goBack();
      } else {
        setState({ ...state, isSubmitting: false });
        toast.error(res.meta.message, { id: toastId });
      }
    });
  };

  const renderNPRSuccessView = no => {
    const { customerFeed: stateCustomerFeed } = state;

    switch (no) {
      case 7:
        return (
          <Fragment>
            <p className="text-theme expected-date mrg-btm-15">
              Pickup scheduled for{' '}
              {moment(stateCustomerFeed.preferred_date).format('DD-MM-YYYY')}
            </p>
            <p className="mrg-btm-10">
              Our courier partner will attempt to pickup at the following
              address
            </p>
            <p className="mrg-btm-20">
              <strong>
                {stateCustomerFeed.address}
                <br />
                {stateCustomerFeed.drop_pincode}
                <br />
                {stateCustomerFeed.landmark}
              </strong>
            </p>
          </Fragment>
        );
      case 6:
      case 3:
      case 4:
        return (
          <Fragment>
            <p className="text-theme expected-date mrg-btm-15">
              Pickup scheduled for{' '}
              {moment(
                stateCustomerFeed.preferred_date ?? customerFeed.preferred_date
              ).format('DD-MM-YYYY')}
            </p>
          </Fragment>
        );
      case 5:
        return (
          <Fragment>
            <p className="text-theme expected-date mrg-btm-15">
              {stateCustomerFeed.preferred_date || customerFeed.preferred_date
                ? 'Pickup scheduled for ' +
                  moment(
                    stateCustomerFeed.preferred_date ??
                      customerFeed.preferred_date
                  ).format('DD-MM-YYYY')
                : null}
            </p>
          </Fragment>
        );
      case 1:
        return (
          <Fragment>
            <p className="text-theme expected-date mrg-btm-15">
              Pickup scheduled for{' '}
              {moment(stateCustomerFeed.preferred_date).format('DD-MM-YYYY')}
            </p>
            {stateCustomerFeed.phone_number ? (
              <p className="mrg-btm-20">
                Our courier partner will attempt to contact you on +91{' '}
                <strong>{stateCustomerFeed.phone_number}</strong>
              </p>
            ) : null}
            <p className="mrg-btm-20 expected-date-label">
              We will notify you as soon as this order is out for pickup.
            </p>
          </Fragment>
        );
      case 2:
        return (
          <Fragment>
            {stateCustomerFeed.is_rejected ? (
              <p className="text-theme expected-date mrg-btm-15">
                Pickup cancelled
              </p>
            ) : (
              <Fragment>
                {stateCustomerFeed.preferred_date ? (
                  <p className="text-theme expected-date mrg-btm-15">
                    Pickup scheduled for{' '}
                    {moment(stateCustomerFeed.preferred_date).format(
                      'DD-MM-YYYY'
                    )}
                  </p>
                ) : null}
              </Fragment>
            )}
          </Fragment>
        );
      default:
        break;
    }
  };

  const DateField = () => (
    <>
      <span className="label">Preferred pickup date</span>
      <span className={`${!state.preferred_date ? 'custom-calendar' : ''}`}>
        <DatePicker
          onChange={onCalendarChange}
          minDate={new Date()}
          selected={state.preferred_date}
          dateFormat="dd-MM-yyyy"
          isClearable={true}
          includeDates={state.dateMeta.map(item => new Date(item.date))}
        />
      </span>
    </>
  );

  const getHeading = no => {
    const { lang } = user;
    switch (no) {
      case 7:
        return <p>{strings[lang].nprFdForm.address}</p>;
      case 6:
        return (
          <Fragment>
            <p>{strings[lang].nprFdForm.timing.a}</p>
            <p className="mrg-top-10">{strings[lang].nprFdForm.timing.b}</p>
            <p className="mrg-top-10">{strings[lang].nprFdForm.timing.c}</p>
          </Fragment>
        );
      default:
      case 3:
      case 5:
        return <p>{strings[lang].nprFdForm.other}</p>;
      case 1:
        return (
          <Fragment>
            <p>{strings[lang].nprFdForm.phone.a}</p>
            <p className="mrg-top-10">{strings[lang].nprFdForm.phone.b}</p>
          </Fragment>
        );
      case 2:
        return <p>{strings[lang].nprFdForm.reject}</p>;
    }
  };

  const getFields = no => {
    const { lang } = user;
    // We will not show form for buckets 3,5,8 for Nykaa brands
    const doNotShowForm =
      nykaaCustomActions.brands.includes(user.subdomain) &&
      nykaaCustomActions.doNotShowNPRFormBuckets.includes(npr.ops.bucket_id);
    switch (no) {
      case 6:
        return (
          <form className="slick-form">
            {state.dateMeta.length > 0
              ? state.dateMeta.slice(0, 2).map((i, j) => (
                  <div key={j} className="mrg-btm-20">
                    <input
                      id={i.date}
                      data-id={i.date}
                      name="ndr-checkbox"
                      className="ndr-checkbox-days"
                      type="radio"
                      checked={
                        state.customerFeed.preferred_date === i.date
                          ? true
                          : false
                      }
                      onChange={e => dateToggle(e)}
                    />
                    <label className="pre-text" htmlFor={i.date}>{`${
                      i.dayOfWeek
                    } (${moment(i.date).format('DD-MM-YYYY')})`}</label>
                  </div>
                ))
              : null}
            <div className="mrg-btm-20">
              <span className="label">Any other date?</span>
              <span
                className={`${!state.preferred_date ? 'custom-calendar' : ''}`}
              >
                <DatePicker
                  //   placeholderText={strings[lang].anyOtherDate}
                  className="input-text ant-holder"
                  onChange={onCalendarChange}
                  selected={state.preferred_date}
                  dateFormat="dd-MM-yyyy"
                  isClearable={true}
                  includeDates={state.dateMeta
                    .slice(2)
                    .map(item => new Date(item.date))}
                />
              </span>
            </div>
          </form>
        );
      case 5:
        return (
          <form className="slick-form">
            <div className="mrg-btm-20">
              <p className="mrg-top-10 mrg-btm-20">
                {strings[lang].nprFdForm.timing.c}
              </p>
              {state.dateMeta.length > 0
                ? state.dateMeta.slice(0, 2).map((i, j) => (
                    <div key={j} className="mrg-btm-20">
                      <input
                        id={i.date}
                        data-id={i.date}
                        name="ndr-checkbox"
                        className="ndr-checkbox-days"
                        type="radio"
                        checked={
                          state.customerFeed.preferred_date === i.date
                            ? true
                            : false
                        }
                        onChange={e => dateToggle(e)}
                      />
                      <label className="pre-text" htmlFor={i.date}>{`${
                        i.dayOfWeek
                      } (${moment(i.date).format('DD-MM-YYYY')})`}</label>
                    </div>
                  ))
                : null}
              <div className="mrg-btm-20">
                <span className="label">Any other date?</span>
                <span
                  className={`${
                    !state.preferred_date ? 'custom-calendar' : ''
                  }`}
                >
                  <DatePicker
                    //   placeholderText={strings[lang].anyOtherDate}
                    className="input-text ant-holder"
                    onChange={onCalendarChange}
                    selected={state.preferred_date}
                    dateFormat="dd-MM-yyyy"
                    isClearable={true}
                    includeDates={state.dateMeta
                      .slice(2)
                      .map(item => new Date(item.date))}
                  />
                </span>
              </div>
            </div>
            <div id="comment" className="mrg-btm-20">
              <textarea
                name="comment"
                id="comment"
                placeholder="Please enter relevant details"
                maxLength="500"
                onChange={e => changeValue(e)}
                value={state.customerFeed.comment}
              />
              <p className="char-counter">
                {500 - state.customerFeed.comment.length} characters left
              </p>
            </div>
          </form>
        );
      case 1:
        return (
          <form className="slick-form">
            <div
              id="yes"
              role="button"
              tabIndex="0"
              onClick={() => alternateNumber(false)}
              className="mrg-btm-15"
            >
              <input
                className="ndr-checkbox"
                type="radio"
                readOnly={true}
                checked={
                  state.isAltNumber !== null
                    ? state.isAltNumber
                      ? false
                      : true
                    : false
                }
                id="yes"
                name="ndr-checkbox"
              />
              <label className="pre-text" htmlFor="yes">
                {strings[lang].yes}
              </label>
            </div>
            <div
              id="no"
              role="button"
              tabIndex="0"
              onClick={() => alternateNumber(true)}
              className="mrg-btm-20"
            >
              <input
                id="no"
                name="ndr-checkbox"
                className="ndr-checkbox"
                type="radio"
                readOnly={true}
                checked={
                  state.isAltNumber !== null
                    ? state.isAltNumber
                      ? true
                      : false
                    : false
                }
              />
              <label className="pre-text" htmlFor="no">
                Enter alternate number
              </label>
            </div>
            {state.isAltNumber ? (
              <div className="mrg-btm-30 mrg-left-30 pos-rel">
                <input
                  id="phone_number"
                  type="number"
                  className="input phoneNumber"
                  name="phone_number"
                  onChange={e => changeValue(e)}
                  value={state?.customerFeed?.phone_number || ''}
                  maxLength="10"
                  disabled={!state.isAltNumber}
                />
                <span className="ph-prefix">+91 </span>
              </div>
            ) : null}
            <div className="mrg-btm-20">
              <DateField />
            </div>
          </form>
        );
      case 3:
      case 4:
        return doNotShowForm && state.form !== 3 ? null : ( // form 3 is for others flow
          <form className="slick-form">
            <div className="mrg-btm-20">
              <DateField />
            </div>
            <div id="comment" className="mrg-btm-20">
              <textarea
                name="comment"
                id="comment"
                placeholder="Please enter relevant details"
                maxLength="500"
                onChange={e => changeValue(e)}
                value={state.customerFeed.comment}
              />
              <p className="char-counter">
                {500 - state.customerFeed.comment.length} characters left
              </p>
            </div>
          </form>
        );
      case 2:
        return (
          <form className="slick-form">
            <div
              id="yes"
              className="mrg-btm-15"
              role="button"
              tabIndex="0"
              onClick={() => askForPickup(true)}
            >
              <input
                className="ndr-checkbox"
                type="radio"
                readOnly={true}
                checked={
                  state.isRejected !== null
                    ? state.isRejected
                      ? true
                      : false
                    : false
                }
                id="yes"
                name="ndr-checkbox"
              />
              <label className="pre-text" htmlFor="yes">
                {strings[lang].yes}
              </label>
            </div>
            <div
              id="no"
              className="mrg-btm-25"
              role="button"
              tabIndex="0"
              onClick={() => askForPickup(false)}
            >
              <input
                id="no"
                name="ndr-checkbox"
                className="ndr-checkbox"
                type="radio"
                readOnly={true}
                checked={
                  state.isRejected !== null
                    ? state.isRejected
                      ? false
                      : true
                    : false
                }
              />
              <label className="pre-text" htmlFor="no">
                {strings[lang].no}
              </label>
            </div>
            <div
              className={`mrg-btm-20 ${
                state.isRejected === null
                  ? 'hide'
                  : state.isRejected
                  ? 'hide'
                  : 'show'
              }`}
            >
              <DateField />
            </div>
            <div id="comment" className="mrg-btm-20">
              <textarea
                name="comment"
                id="comment"
                placeholder="Please enter relevant details"
                maxLength="500"
                onChange={e => changeValue(e)}
                value={state.customerFeed.comment}
              />
              <p className="char-counter">
                {500 - state.customerFeed.comment.length} characters left
              </p>
            </div>
          </form>
        );
      case 7:
        return (
          <Fragment>
            <p className="heading-4 mrg-btm-15">Enter correct pickup details</p>
            <form className="slick-form">
              <div className="mrg-btm-20">
                <textarea
                  name="address"
                  placeholder="Complete Address"
                  maxLength="255"
                  onChange={e => changeValue(e)}
                  value={state.customerFeed.address || ''}
                  // disabled={!state.customerFeed?.capture?.address}
                />
                <p className="char-counter">
                  {255 - state.customerFeed.address.length} characters left
                </p>
              </div>
              <div className="mrg-btm-20">
                <span className="label">Pincode</span>
                <input
                  className="input pincode"
                  name="drop_pincode"
                  maxLength="6"
                  onChange={e => changeValue(e)}
                  value={state.customerFeed.drop_pincode || ''}
                  // disabled={!state.customerFeed?.capture?.drop_pincode}
                />
              </div>
              <div className="mrg-btm-20">
                <span className="label">Landmark</span>
                <input
                  className="input landmark"
                  name="landmark"
                  maxLength="128"
                  onChange={e => changeValue(e)}
                  value={state.customerFeed.landmark || ''}
                  // disabled={!state.customerFeed?.capture?.landmark}
                />
              </div>
              <div className="mrg-btm-20">
                <span className="label">Preferred pickup date</span>
                <span
                  className={`${
                    !state.preferred_date ? 'custom-calendar' : ''
                  }`}
                >
                  <DatePicker
                    onChange={onCalendarChange}
                    minDate={new Date()}
                    selected={state.preferred_date}
                    dateFormat="dd-MM-yyyy"
                    isClearable={true}
                    includeDates={state.dateMeta.map(
                      item => new Date(item.date)
                    )}
                  />
                </span>
              </div>
            </form>
          </Fragment>
        );
      default:
    }
  };
  // Truncated content shown for Nykaa brands when form is not visible
  const getContentId = id => {
    switch (id) {
      case 3:
        return '3a';
      case 8:
        return '8a';
      case 5:
        return '5a';
      default:
        return id;
    }
  };

  const renderScreen = () => {
    const { lang } = user;
    const issueTypes = [
      { id: 1, label: strings[lang].nprAgentDidntCall },
      { id: 7, label: strings[lang].nprNoAttemptToAddress },
      { id: 6, label: strings[lang].nprWasNotAvailable },
      { id: 2, label: strings[lang].doNotWantReturnProduct },
      { id: 3, label: 'Others' }
    ];
    const { screen, form } = state;
    const sanitizer = dompurify.sanitize;
    // We will not show form for buckets 3,5,8 for Nykaa brands
    const doNotShowForm =
      nykaaCustomActions.brands.includes(user.subdomain) &&
      nykaaCustomActions.doNotShowNPRFormBuckets.includes(npr.ops.bucket_id);
    switch (screen) {
      case 1:
        return (
          <div className={`${screen === 1 ? 'show' : 'hide'} `}>
            <div className="content">
              <p className="expected-date mrg-btm-20">
                Could not pick up your order
              </p>
              <p
                dangerouslySetInnerHTML={{
                  __html: sanitizer(
                    strings[lang].nprBucket(
                      doNotShowForm
                        ? getContentId(npr.ops.bucket_id)
                        : npr.ops.bucket_id
                    )
                  )
                }}
              />
              <div className="mrg-top-20">{getFields(npr.ops.issue_id)}</div>
              <div className="request-block pd-15 col-gutter-20 xs-col-gutter-10 mrg-btm-10">
                {doNotShowForm ? null : (
                  <div className="pd-top-15 pd-btm-5">
                    <button
                      className={`nyk-btn text-uppercase`}
                      onClick={handleSubmit}
                      disabled={state.isSubmitting}
                    >
                      {strings[lang].submit}
                    </button>
                  </div>
                )}
                <p className="pd-top-20 pd-btm-20 nyk-issue-btn">
                  <span
                    className="cur-pointer"
                    role="button"
                    tabIndex="0"
                    onClick={e => handleClick(e, 2)}
                  >
                    ARE YOU FACING ANY OTHER ISSUE?
                  </span>
                </p>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <form className={`slick-form ${screen === 2 ? 'show' : 'hide'}`}>
            <p className="expected-date">{strings[lang].pleaseTellUs}</p>
            <p className="char-counter text-left mrg-btm-15">
              <em>
                Press <span className="highlight">Next</span> button at bottom
                after selection.
              </em>
            </p>
            {issueTypes.map((issue, i) => (
              <div key={i} className="mrg-btm-15">
                <input
                  id={issue.id}
                  name="ndr-checkbox"
                  className="ndr-checkbox"
                  type="radio"
                />
                <label
                  id={issue.id}
                  className="pre-text"
                  htmlFor={issue.id}
                  onClick={e => handleRadio(e, issue.id)}
                  role="presentation"
                >
                  {issue.label}
                </label>
              </div>
            ))}
            <div className="request-block pd-15 col-gutter-20 xs-col-gutter-10 mrg-btm-10">
              <div className="pd-top-15 pd-btm-5">
                <button
                  className={`nyk-btn ${form === 0 ? 'disabled' : ''}`}
                  disabled={form === 0}
                  onClick={e => handleClick(e, 3)}
                >
                  {strings[lang].next}
                </button>
              </div>
              <p className="pd-top-20 pd-btm-20 nyk-issue-btn">
                <span
                  className="cur-pointer"
                  onClick={e => handleClick(e, 99)}
                  role="presentation"
                >
                  BACK
                </span>
              </p>
            </div>
          </form>
        );
      case 3:
        return (
          <div className={`${screen === 3 ? 'show' : 'hide'}`}>
            <p className="expected-date mrg-btm-20">
              Could not pick up your order
            </p>
            <div className="mrg-top-10 mrg-btm-20">{getHeading(form)}</div>
            {getFields(form)}
            <div className="request-block pd-15 col-gutter-20 xs-col-gutter-10 mrg-btm-10">
              <div className="pd-top-15 pd-btm-5">
                <button
                  className={`nyk-btn text-uppercase ${
                    state.isSubmitting ? 'disabled' : ''
                  }`}
                  onClick={handleSubmit}
                >
                  {state.isSubmitting ? 'SUBMITTING...' : strings[lang].submit}
                </button>
              </div>
              <p className="pd-top-20 pd-btm-20 nyk-issue-btn">
                <span
                  className="cur-pointer"
                  onClick={e => handleClick(e, 2)}
                  role="presentation"
                >
                  BACK
                </span>
              </p>
            </div>
          </div>
        );

      case 4:
        return (
          <Fragment>
            {renderNPRSuccessView(state.customerFeed.issue_id ?? 0)}
            <div className="request-block pd-15 col-gutter-20 xs-col-gutter-10 mrg-btm-10">
              <p className="pd-top-20 pd-btm-20 nyk-issue-btn">
                <span
                  className="cur-pointer"
                  onClick={e => handleClick(e, 2)}
                  role="presentation"
                >
                  ARE YOU FACING ANY OTHER ISSUE?
                </span>
              </p>
            </div>
          </Fragment>
        );
      default:
    }
  };

  return <>{renderScreen()}</>;
};

Issue.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  rating: PropTypes.object,
  customerFeed: PropTypes.object,
  npr: PropTypes.object,
  page: PropTypes.object,
  history: PropTypes.object,
  opsFeed: PropTypes.object,
  security_key: PropTypes.string
};

export default Issue;
