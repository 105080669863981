import React, { useState, createRef, useEffect } from 'react';
import { strings } from '../../utils/localizedStrings';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { postRatingHandler } from '../../service';
import { withRouter } from 'react-router-dom';
import RatingFirstStep from './RatingFirstStep';

const WizardRating = ({
  trackingId,
  lang,
  feedbackMessage,
  location,
  priorRating
}) => {
  const [state, setState] = useState({
    step: 0,
    rating: null,
    comment: null,
    placeholder: null
  });
  const commentRef = createRef();

  const onButtonClick = rating => {
    state.placeholder = strings[lang].ratingMeta(rating);
    setState({ ...state, rating });
  };

  useEffect(() => {
    if (priorRating.ratedBefore) {
      setState({
        ...state,
        rating: priorRating.star,
        comment: priorRating.comment,
        step: 2
      });
    }
  }, [priorRating]);

  const onSubmit = () => {
    setState({ ...state, step: state.step + 1 });
    if (state.step === 1) {
      const toastId = toast.loading(strings[lang].actionInProgress);
      const { search, hash } = location;
      const searchParams = new URLSearchParams(search + hash);
      const query = {
        tracking_id: trackingId,
        comment: state.comment,
        courier_rating: state.rating,
        security_key:
          searchParams.get('security_key') || searchParams.get('key')
      };

      if (searchParams.has('source') || searchParams.has('utm_source')) {
        query.feedback_source =
          searchParams.get('source') || searchParams.get('utm_source');
      }

      postRatingHandler(query).then(res => {
        if (res?.meta?.status == 200) {
          if (state.rating > 5) {
            toast.success(strings[lang].feedbackToastr1, { id: toastId });
          } else {
            toast.success(strings[lang].feedbackToastr2, { id: toastId });
          }
        }
      });
    }
  };

  const renderWizardForm = () => {
    switch (state.step) {
      case 0:
        return (
          <RatingFirstStep
            state={state}
            feedbackMessage={feedbackMessage}
            onSubmit={onSubmit}
            lang={lang}
            onButtonClick={onButtonClick}
          />
        );
      case 1:
        return (
          <>
            <p className="li-ht-24 fs-20 fw-600">{state.placeholder}</p>
            <div className="mrg-top-10">
              <textarea
                ref={commentRef}
                placeholder={strings[lang].inputFeedback}
                className="input-textarea"
                onChange={e => setState({ ...state, comment: e.target.value })}
              />
            </div>
            <div className="mrg-top-15">
              <button className={`submit button rating`} onClick={onSubmit}>
                {strings[lang].submit}
              </button>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="mrg-top-10 rating-submitted">
              <p>{strings[lang].youRatedUs}</p>
              <p className="text-gray mrg-top-5">{state.rating} out of 10</p>
              {state.comment?.trim() ? (
                <>
                  <p className="mrg-top-10">{strings[lang].youCommented}</p>
                  <p className="text-gray mrg-top-5">{state.comment}</p>
                </>
              ) : null}
            </div>
          </>
        );
      default:
        break;
    }
  };

  return (
    <div className="card text-left">
      <div className="rating-container">
        <p>
          {state.step === 2
            ? strings[lang].ratingFeedback
            : strings[lang].feedbackMatters}
        </p>
        <div className="rating-progress-bar mrg-top-10 mrg-btm-20">
          <div className={`progress-value step${state.step}`}></div>
        </div>
        {renderWizardForm()}
      </div>
    </div>
  );
};

WizardRating.propTypes = {
  lang: PropTypes.string,
  priorRating: PropTypes.bool,
  subdomain: PropTypes.string,
  feedbackMessage: PropTypes.string,
  trackingId: PropTypes.string,
  location: PropTypes.object
};
export default withRouter(WizardRating);
