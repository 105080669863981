import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

const SpecialMessage = props => (
  <div
    className={`card meta-card`}
    style={{ height: '100%', minHeight: 'unset' }}
  >
    <div className={`row mrg-0 mrg-top-20 text-left pd-top-10 pd-btm-10`}>
      <div className={`col-xs-24 bd-left-0 md-vertical-align xs-top-align`}>
        <span className="icon-bell_icon fs-25 mrg-right-10" />
        <span className="fs-14">{props.message}</span>
      </div>
    </div>
  </div>
);

SpecialMessage.propTypes = {
  isMobile: PropTypes.bool,
  message: PropTypes.string
};

export default withRouter(connect(null, null)(SpecialMessage));
