import React, { useEffect, useState } from 'react';
import { strings } from '../utils/localizedStrings';
import RatingFirstStep from './rating/RatingFirstStep';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { postBrandRatingHandler } from '../service';
import toast from 'react-hot-toast';

const BrandRating = ({ user, tracking, rating, page }) => {
  const { lang } = user;
  const { udfKeys } = tracking.theme;
  const [state, setState] = useState({
    step: 0,
    starsUI: {},
    rating: null,
    comment: '',
    stars: {}
  });

  const {
    nps_question,
    nps_review_questions_dict,
    npsConfig
  } = tracking.theme.config;
  const { udf } = tracking.theme.meta_data;

  const commentLabel = udf?.find(value => value.key === 'npsCommentLabel');
  const commentPlaceholder = udf?.find(
    value => value.key === 'npsCommentPlaceholder'
  );

  const questionsDict = npsConfig?.custom_questions_enabled
    ? Object.keys(npsConfig.nps_custom_questions).reduce((nextDict, curr) => {
        if (npsConfig.nps_custom_questions[curr]) {
          nextDict[npsConfig.nps_custom_questions[curr]] = curr;
        }
        return nextDict;
      }, {})
    : nps_review_questions_dict;

  const onButtonClick = rating => {
    state.rating = rating;
    setState({ ...state });
  };

  const onSubmit = () => {
    let toastId;
    let query = {
      ...rating,
      tracking_id: rating.tracking_id,
      default_brand_experience: rating.default_brand_experience ?? state.rating,
      subdomain: user.subdomain,
      feedback: state.comment,
      security_key: page.security_key,
      ...state.stars
    };

    if (page?.utm_source || page?.source) {
      query.utm_source = page.utm_source || page.source;
    }

    if (state.step === 1) {
      query.ratedBefore = true;
      toastId = toast.loading(strings[lang].actionInProgress);
    }

    postBrandRatingHandler(query)
      .then(() => {
        state.step += 1;
        if (state.step === 2) {
          if (
            Object.values(state.stars).length > 0 &&
            Object.values(state.stars).every(val => val > 2)
          ) {
            toast.success(strings[lang].feedbackToastr1, { id: toastId });
          } else {
            toast.success(strings[lang].feedbackToastr2, { id: toastId });
          }
        }
        setState({ ...state });
      })
      .catch(err => {
        toast.error(err.message);
      });
  };

  useEffect(() => {
    if (rating.ratedBefore) {
      state.step += 1;
    }
    if (
      Object.values(questionsDict).every(key => typeof rating[key] === 'number')
    ) {
      state.step += 1;
    }
    setState({ ...state });
  }, []);

  const renderStep = () => {
    const isLongText = Object.keys(questionsDict ?? {}).some(
      item => item.length > 50
    );
    switch (state.step) {
      case 0:
        return (
          <RatingFirstStep
            state={state}
            onSubmit={onSubmit}
            onButtonClick={onButtonClick}
            lang={lang}
            npsType={udfKeys.npsType ?? ''}
            feedbackMessage={strings[lang].recommendToFriend(nps_question)}
            isBrand
          />
        );
      case 1:
        return (
          <>
            <p className="mrg-btm-10 fs-20 fw-600">
              {udfKeys?.npsRateusQuestion
                ? udfKeys?.npsRateusQuestion
                : strings[lang].rateUs}
            </p>
            <div className="row">
              {Object.keys(questionsDict ?? {}).map((question, idx) => (
                <div
                  key={question}
                  className={`col-xs-24 ${
                    isLongText
                      ? `col-md-12 ${idx > 1 ? '' : 'lg-min-ht-100'}`
                      : 'col-md-12 col-lg-9'
                  } mrg-btm-10`}
                >
                  <div className="row full-width pd-0">
                    <p
                      className={`${
                        isLongText ? 'col-xs-24' : 'col-xs-12'
                      } col-md-12 fs-18 mrg-btm-10 pd-top-5`}
                    >
                      {strings[lang].npsQuestions.hasOwnProperty(question)
                        ? strings[lang].npsQuestions[question]
                        : question}
                        *
                    </p>
                    <div
                      className={`${
                        isLongText ? 'col-xs-24' : 'col-xs-12'
                      } col-md-12 pd-right-0`}
                    >
                      <div className="nps-star-container d-flex ">
                        {Array.from({ length: 5 }, (v, i) => i + 1).map(key => (
                          <div
                            key={key}
                            role={'button'}
                            tabIndex={0}
                            onMouseEnter={() => {
                              state.starsUI[questionsDict[question]] = key;
                              setState({ ...state });
                            }}
                            onMouseLeave={() => {
                              state.starsUI[questionsDict[question]] = null;
                              setState({ ...state });
                            }}
                            onKeyDown={() => {
                              state.stars[questionsDict[question]] = key;
                              setState({ ...state });
                            }}
                            className={`nps-star ${
                              state.stars[questionsDict[question]] >= key ||
                              state.starsUI[questionsDict[question]] >= key
                                ? 'active'
                                : ''
                            }`}
                            onClick={() => {
                              state.stars[questionsDict[question]] = key;
                              setState({ ...state });
                            }}
                          />
                        ))}
                      </div>
                    </div>{' '}
                  </div>
                </div>
              ))}
            </div>
            <p className="li-ht-24 fs-20 fw-600">
              {commentLabel?.value ??
                strings[lang].ratingMeta(
                  state.rating ?? rating.default_brand_experience
                )}
            </p>
            <div className="mrg-top-10">
              <textarea
                // ref={commentRef}
                placeholder={
                  commentPlaceholder?.value ?? strings[lang].inputFeedback
                }
                className="input-textarea"
                onChange={e => setState({ ...state, comment: e.target.value })}
              />
            </div>
            <div className="mrg-top-15">
              <button
                className={`submit button rating`}
                onClick={onSubmit}
                disabled={Object.values(questionsDict).some(
                  key => !state.stars[key]
                )}
              >
                {strings[lang].submit}
              </button>
            </div>
          </>
        );
      case 2:
        return (
          <div className="mrg-top-10 rating-submitted">
            <div className="row pd-0">
              <div
                className={`col-xs-24 ${
                  isLongText ? 'col-md-22' : 'col-md-15'
                }  row no-gutter pd-0`}
              >
                <div
                  className={`col-xs-14 ${
                    isLongText ? 'col-md-7' : 'col-md-6'
                  }`}
                >
                  NPS Rating
                </div>
                <div
                  className={`col-xs-10 ${
                    isLongText ? 'col-md-17' : 'col-md-18'
                  } text-gray`}
                >
                  {rating.default_brand_experience ?? state.rating} out of 10
                </div>
                {Object.keys(questionsDict ?? {}).map((question, idx) => (
                  <React.Fragment key={question}>
                    <div
                      className={`col-xs-14 ${
                        isLongText
                          ? `col-md-7 ${
                              idx > 1 ? '' : 'lg-min-ht-100 xl-min-ht-50'
                            }`
                          : 'col-md-6'
                      }  mrg-top-10`}
                    >
                      {question}
                    </div>
                    <div
                      className={`col-xs-10 ${
                        isLongText ? 'col-md-5' : 'col-md-6'
                      } mrg-top-10`}
                    >
                      {Array.from({ length: 5 }, (v, i) => i + 1).map(val => (
                        <span
                          key={val}
                          className={`nps-star small ${
                            val <=
                            (rating[questionsDict[question]] ??
                              state.stars[questionsDict[question]])
                              ? 'active'
                              : ''
                          }`}
                        />
                      ))}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
            {rating.feedback?.trim() || state.comment?.trim() ? (
              <>
                <p className="mrg-top-10">Feedback:</p>
                <p className="text-gray mrg-top-5">
                  {rating.feedback || state.comment}
                </p>
              </>
            ) : null}
          </div>
        );
    }
  };

  return (
    <div className="card text-left">
      <div className="rating-container brand-rating">
        <p>
          {state.step === 2
            ? strings[lang].ratingFeedback
            : strings[lang].feedbackMatters}
          {state.step < 2 && (
            <span className="text-gray mrg-left-5">{state.step + 1} / 2</span>
          )}
        </p>
        <div className="rating-progress-bar mrg-top-10 mrg-btm-20">
          <div className={`progress-value step${state.step}`}></div>
        </div>
        {renderStep()}
      </div>
    </div>
  );
};

BrandRating.propTypes = {
  user: PropTypes.any,
  tracking: PropTypes.any,
  rating: PropTypes.any,
  page: PropTypes.any
};

const mapStateToProps = state => ({
  user: state.user,
  tracking: state.tracking,
  rating: state.rating,
  page: state.page
});

export default connect(mapStateToProps)(BrandRating);
