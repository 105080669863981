import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { ga4ClickEvent } from '../utils/helper';

const AppPopUp = ({ user, tracking, page }) => {
  const [state, setState] = useState(false);
  useEffect(() => {
    if (user.appLink) {
      setTimeout(() => setState(true), 7000);
    }
  }, []);

  const close = () => {
    setState(false);
  };

  const gaEvents = (e, eventName, label, action) => {
    if (action && user.env !== 'development') {
      ga4ClickEvent({
        category: eventName,
        action,
        label,
        props: { user, tracking, page }
      });
      ReactGA.set({ dimension1: user.enterprise });
      ReactGA.set({ dimension2: tracking.latestStatus.code });
      ReactGA.set({ dimension4: tracking.waybill });
      ReactGA.set({ dimension5: tracking.courier.actName });
      ReactGA.set({ dimension7: page.source || '' });
      ReactGA.set({ dimension8: tracking.courier.id });
      ReactGA.set({ dimension9: tracking.theme.enterprise_user_id });
      ReactGA.event({
        category: eventName,
        label: label,
        action: action
      });
    }
  };

  if (!state) {
    return null;
  }
  return (
    <div className="row app-link visible-xs">
      <div className="col-xs-5 pd-0">
        <a
          href={user.appLink}
          target="_blank"
          onClick={e => this.gaEvents(e, 'App Install', user.device)}
          rel="noopener noreferrer"
        >
          <img src={user.icon} width="60" alt="app icon" />
        </a>
      </div>
      <div className="col-xs-12 mrg-top-15">
        <p className="mrg-btm-10">Get the app now for exciting new offers!</p>
      </div>
      <div className="col-xs-7 pd-0 text-right">
        <span
          className="icon-close"
          role="presentation"
          onClick={close}
          onKeyDown={close}
        />
        <a
          className="button"
          href={user.appLink}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => gaEvents(e, 'App Install', user.device, user.appLink)}
        >
          INSTALL
        </a>
      </div>
    </div>
  );
};

AppPopUp.propTypes = {
  user: PropTypes.object,
  tracking: PropTypes.object,
  page: PropTypes.object
};

export default memo(AppPopUp);
