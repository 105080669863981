/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactGA from 'react-ga';
import {
  updateUserHandler,
  opsFeedHandler,
  customerFeedHandler
} from '../../../service';
import Slider from 'react-slick';
import { openUrl } from '../../../utils/helper';

let activeSlide = 0;
class MetaCard extends Component {
  constructor(props) {
    super(props);
    this.gaEvents = this.gaEvents.bind(this);
    this.shareUrl = this.shareUrl.bind(this);
    this.openShare = this.openShare.bind(this);
    this.linkButton = this.linkButton.bind(this);
  }
  goForward(route) {
    const { tracking, customerFeed, user } = this.props;
    const { routePrefix } = user;
    if (route === 'issue') {
      this.props
        .fetchOpsFeedHandler(tracking.trackingId, tracking.config.user)
        .then(res => {
          if (res.meta && res.meta.success) {
            this.props
              .fetchCustomerFeedHandler(tracking, tracking.customer)
              .then(res => {
                if (res.meta && res.meta.success && customerFeed) {
                  this.context.router.history.push(`/${routePrefix ? `${routePrefix}` : ''}/issue${this.context.router.history.location.search}`);
                }
              });
          }
        });
    } else {
      this.context.router.history.push(
        `/${route}` + this.context.router.history.location.search
      );
    }
  }

  linkButton = e => {
    const { user, tracking } = this.props;
    e.preventDefault();
    let banners = [];
    if (tracking.theme.meta_data && tracking.theme.meta_data.banners) {
      banners = tracking.theme.meta_data.banners.filter(
        item => item.type === 'top'
      );
    }
    const { redirect_url, title } = banners[activeSlide];
    ReactGA.event({
      category: 'Tile',
      action: redirect_url,
      label: title
    });
    if (user.webview) {
      window.location = redirect_url;
    } else {
      openUrl(redirect_url, '_blank');
      // window.open(redirect_url, '_blank');
    }
  };

  gaEvents(e, eventName, label, action) {
    if (action) {
      ReactGA.event({
        category: eventName,
        label: label,
        action: action
      });
    }
  }

  shareUrl(name) {
    const { tracking, user, page } = this.props;
    const enterpriseProfile = tracking.theme.meta_data;
    const hostUrl = user.userHostname + page.url;
    switch (name) {
      case 'whatsapp':
        return `whatsapp://send?text=Track my order @%20${
          hostUrl
        }?ref=social_wa_clickpost`;
      case 'facebook':
        return `https://www.facebook.com/dialog/share?_encoding=UTF8&display=popup&href=${
          hostUrl
        }&app_id=1932988240339501&redirect_uri=${encodeURIComponent(
          hostUrl
        )}&hashtag=%23${enterpriseProfile.hashtag}&quote=Track my order`;
      case 'twitter':
        return `https://twitter.com/intent/tweet?original_referer=${
          hostUrl
        }&ref=social_tw_clickpost&text=Track my order&url=${
          hostUrl
        }&ref=social_tw_clickpost&via=${enterpriseProfile.twitterHandle}`;
      default:
    }
  }

  openShare() {
    let { user } = this.props;
    user = Object.assign(user, { shareOn: true });
    this.props.updateUserHandler(user);
  }

  render() {
    const { tracking, handleImageLoad } = this.props;
    let banners = [];
    if (tracking.theme.meta_data && tracking.theme.meta_data.banners) {
      banners = tracking.theme.meta_data.banners.filter(
        item => item.type === 'top'
      );
    }
    const settings = {
      infinite: true,
      autoplaySpeed: 3000,
      autoplay: true,
      margin: 0,
      centerMode: true,
      centerPadding: '0px',
      arrows: true,
      slidesToScroll: 1,
      swipeToSlide: false,
      dots: false,
      fade: true,
      speed: 500,
      slidesToShow: 1,
      verticalSwiping: false,
      draggable: false,
      swipe: false,
      beforeChange: (current, next) => {
       activeSlide = next
      }
    };
    const slides = banners.map((item, i) => {
      return (
        <div className="banner cur-pointer" key={i}>
          <img
            src={item.url}
            title={item.title}
            alt={item.alt}
            onClick={e => this.linkButton(e, item)}
            onLoad={handleImageLoad}
          />
        </div>
      );
    });
    return (
      <>
      <div
        className="card meta-card"
        style={{ height: '100%', minHeight: 'unset' }}
      >
        <div className="row mrg-0 text-left pd-top-15 pd-btm-15">
          <div className="col-md-6 col-xs-12 order-info xs-mrg-btm-10">
            <span className="label">Courier</span>
            <span className="text">{tracking.courier.name}</span>
          </div>
          <div className="col-md-6 col-xs-12 order-info xs-mrg-btm-10">
            <span className="label">Tracking ID</span>
            <span className="text">{tracking.waybill}</span>
          </div>
          {
            tracking.config.isRVP ? null :
            <div className="col-md-6 col-xs-12 order-info">
              <span className="label">Order ID</span>
              <span className="text">
                {tracking.orderId != null ? tracking['orderId'] : 'NA'}
              </span>
            </div>
          }
          <div className={`col-md-6 col-xs-12 order-info`}>
            <span className="label">Order Placed On</span>
            <span className="text">
              {tracking.config.orderDate ? moment(tracking.config.orderDate.slice(0, 10)).format(
                'Do MMM YY'
              ) : 'NA'}
            </span>
          </div>
        </div>
      </div>
      {banners.length > 0 &&
        tracking.theme.meta_data &&
        (tracking.theme.meta_data.ads_enabled ||
          tracking.theme.meta_data.ads_enabled === undefined) ? (
          <div className="row">
            <div className="col-md-24 col-xs-24 mrg-top-20 xs-mrg-top-10">
              <Slider {...settings}>{slides}</Slider>
            </div>
          </div>
        ) : null}
        </>
    );
  }
}

MetaCard.contextTypes = {
  router: PropTypes.object.isRequired
};

MetaCard.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  page: PropTypes.object,
  customerFeed: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  updateUserHandler: PropTypes.func,
  fetchOpsFeedHandler: PropTypes.func,
  fetchCustomerFeedHandler: PropTypes.func,
  handleImageLoad: PropTypes.func,
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  rating: state.rating,
  user: state.user,
  page: state.page,
  customerFeed: state.customerFeed
});

const mapDispatchToProps = dispatch => ({
  updateUserHandler: user => dispatch(updateUserHandler(user)),
  fetchOpsFeedHandler: (trackingId, user) =>
    dispatch(opsFeedHandler(trackingId, user)),
  fetchCustomerFeedHandler: (tracking, customerInfo) =>
    dispatch(customerFeedHandler(tracking, customerInfo))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MetaCard);
