import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const RecommendedProducts = props => {
  const [mappedProducts, setProducts] = useState({
    isLoading: true,
    list: [],
    slidesToShow: 0,
    slidesToScroll: 0
  });

  useEffect(() => {
    const onLoad = () => {
      let slidesToShow = 0;
      let list = props.products.list;
      let length = list.length;
      let imgColClass = '';
      if (length > 0) {
        if (
          ['oriflame-id', 'oriflame-test'].includes(props?.user?.enterprise)
        ) {
          slidesToShow = length;
          imgColClass = 'col-md-3';
        } else if (length >= 6) {
          slidesToShow = 6;
          imgColClass = 'col-md-4';
        } else if (length >= 4) {
          slidesToShow = length;
          imgColClass = 'col-md-6';
        } else {
          imgColClass = 'col-md-8';
        }
      }
      setProducts({
        isLoading: false,
        list: list,
        config: {
          ...props.products.config,
          columnClass: `${imgColClass} col-xs-12 xs-mrg-btm-15`
        },
        slidesToShow: slidesToShow,
        slidesToScroll: props.products.config.slidesToScroll
      });
    };

    props.products && onLoad();
  }, [props]);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: mappedProducts.slidesToShow,
    slidesToScroll: mappedProducts.slidesToScroll,
    autoplay: true,
    autoplaySpeed: 3000
  };

  const onClickImage = (item, index) => {
    const activeSlides = document.getElementsByClassName('slick-active');
    const activeIndexes = [];
    for (let i = 1; i < activeSlides.length; i++) {
      activeIndexes.push(Number(activeSlides[i].dataset.index));
    }
    const clickedIndex = activeIndexes.indexOf(index);

    const { user, tracking, page } = props;
    // Added another event
    ReactGA4.event('custom_click', {
      category: 'Recommended Products',
      label: item.title,
      activity: item.redirectUrl,
      value: clickedIndex > -1 ? clickedIndex : index,
      custom_category: 'Recommended Products',
      custom_label: item.title,
      custom_action: item.redirectUrl,
      enterprise: user.enterprise?.toString(),
      uber_status: tracking.latestStatus.code?.toString(),
      waybill: tracking.waybill?.toString(),
      cp_name: tracking.courier.actName,
      tracking_source: page.source || '',
      cp_id: tracking.courier.id?.toString(),
      enterprise_id: tracking.theme.enterprise_user_id?.toString()
    });
    ReactGA4.event('user_click', {
      category: 'Recommended Products',
      label: item.title,
      activity: item.redirectUrl,
      value: clickedIndex > -1 ? clickedIndex : index,
      enterprise: user.enterprise,
      uber_status: tracking.latestStatus.code,
      waybill: tracking.waybill,
      cp_name: tracking.courier.actName,
      tracking_source: page.source || '',
      cp_id: tracking.courier.id,
      enterprise_id: tracking.theme.enterprise_user_id
    });
    ReactGA.event({
      category: 'Recommended Products',
      action: item.redirectUrl,
      label: item.title,
      metric1: clickedIndex > -1 ? clickedIndex : index
    });
  };

  const mapProducts = () =>
    mappedProducts.list
      .filter(
        item =>
          item.imageUrl !== null &&
          item.imageUrl !== '' &&
          item.imageUrl !== undefined
      )
      .map((item, index) => (
        <div key={index} className={mappedProducts.config.columnClass}>
          <a href={item.redirectUrl} target="_blank" rel="noreferrer noopener">
            <div
              className="item-img img-rounded"
              style={{
                backgroundImage: `url(${item.imageUrl})`
              }}
              onClick={() => onClickImage(item, index)}
              data-index={index}
              aria-hidden="true"
            />
          </a>
          <div className="item-info mg-top-10">
            {mappedProducts.config.mandatoryAttributes?.discounted &&
              item?.discount && (
                <>
                  <span className="item-discount">
                    {mappedProducts.config.modifier === 'currency'
                      ? mappedProducts.config.currency + ' ' + item?.discount
                      : parseInt(item.discount, 10) + '% '}{' '}
                    off
                  </span>
                </>
              )}
            <span className="item-title">{item.title}</span>
            {mappedProducts.config.mandatoryAttributes?.description && (
              <span className="item-desc">{item?.description}</span>
            )}
            <div className="item-pricing-info">
              {mappedProducts.config.mandatoryAttributes?.discountedPrice &&
                item?.discountedPrice &&
                item?.discountedPrice !== item?.retailPrice && (
                  <span className="item-discount-price">
                    {mappedProducts.config.currency}{' '}
                    {item.discountedPrice.toLocaleString()}
                  </span>
                )}
              {mappedProducts.config.mandatoryAttributes?.retailPrice &&
                item?.retailPrice && (
                  <>
                    <span
                      className={
                        item?.discountedPrice !== item?.retailPrice
                          ? 'item-retail-price'
                          : 'item-discount-price'
                      }
                    >
                      {mappedProducts.config.currency}{' '}
                      {item?.retailPrice?.toLocaleString()}
                    </span>
                  </>
                )}
            </div>
          </div>
        </div>
      ));

  const herbalifeTitle =
    'Unduh Aplikasi Herbalife Hub dan rasakan Pelacakan & Penelusuran Pesanan sekarang!';

  return (
    <div className="col-xs-24 col-md-24 mrg-top-20 xs-mrg-top-10">
      <div className={`product-card xs-pd-top-0`}>
        <div className="card-title">
          {!mappedProducts.isLoading && (
            <>
              {(props?.subdomain === 'herbalife-id' ||
                props?.subdomain === 'herbalife-test') &&
              props?.lang === 'id'
                ? herbalifeTitle
                : mappedProducts.config.title}
            </>
          )}
        </div>
        <div className="card-subtitle">
          {!mappedProducts.isLoading && mappedProducts.config.subTitle}
        </div>
        <div className="card-body">
          {props.device === 'desktop' &&
          mappedProducts.list.length >= 4 &&
          !mappedProducts.isLoading ? (
            <Slider {...settings}>{mapProducts()}</Slider>
          ) : (
            <div className="row products-row">{mapProducts()}</div>
          )}
        </div>
      </div>
    </div>
  );
};

RecommendedProducts.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  page: PropTypes.object,
  mappedProducts: PropTypes.object,
  products: PropTypes.object,
  device: PropTypes.string,
  subdomain: PropTypes.string,
  lang: PropTypes.string
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user,
  page: state.page
});

export default withRouter(connect(mapStateToProps, null)(RecommendedProducts));
