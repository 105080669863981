/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react/no-string-refs */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
// import 'url-search-params-polyfill';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

import Timeline from './timeline';
import Banner from './banner';
import RecommendProducts from './recommendProducts';
import MetaCard from './meta';
import RatingCard from './rating';
import AppPopUp from './appPopUp';
import NotFoundCard from './NotFoundCard';
// import RequestCard from './components/request';
import layout from '../utils/layout_config';
import {
  pageHandler,
  trackingHandler,
  ratingHandler,
  opsFeedHandler,
  customerFeedHandler,
  updateUserHandler,
  eddHandler,
  nprOpsFeedHandler,
  nprCustomerFeedHandler
  // pageRedirectionHandler
} from '../service';
import { PageSpinner } from '../staticComponents/spinner';

import { reverseOrder, isUrl } from '../utils/entity_mapping';
import BrandRating from './BrandRating';
import SpecialMessage from './specialMessage';
import { ga4ClickEvent, zipyIdentify } from '../utils/helper';
import Payment from './Payment';

class Tracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      action: { show: false, active: 0, notification: false },
      width: 0,
      characters: 0,
      appLink: '',
      showAppLink: false,
      subdomain: '',
      shareOn: false,
      counter: 0,
      invalidShipment: false,
      message: '',
      subMessage: ''
    };
    // this.toggleTopNotification = this.toggleTopNotification.bind(this);
    this.getBlockComponent = this.getBlockComponent.bind(this);
    this.closeAppPopUp = this.closeAppPopUp.bind(this);
    this.closeShare = this.closeShare.bind(this);
    this.fetchTracking = this.fetchTracking.bind(this);
  }

  fetchTracking() {
    const { page } = this.props;
    const { search, pathname, hash } = this.props.location;
    const searchParams = new URLSearchParams(search + hash);
    const security_key =
      searchParams.get('security_key') || searchParams.get('key');
    const query = {
      cpId: searchParams.get('cp_id'),
      waybill: searchParams.get('waybill'),
      orderId: searchParams.get('order_id'),
      security_key: security_key,
      courierRating: searchParams.get('courier_rating'),
      app_only: searchParams.get('app_only'),
      altUrl: searchParams.get('altUrl')
    };
    if (query.orderId) {
      query.orderId = encodeURIComponent(query.orderId);
    }

    const url = pathname + search;
    this.props.setPageHandler(
      Object.assign(
        query,
        { url },
        {
          isSearch: query.cpId || query.waybill || query.orderId ? false : true
        }
      )
    );
    const { isNdrFetched, theme } = this.props.tracking;
    const { routePrefix } = this.props.user;
    if ((query.cpId || query.waybill || query.orderId) && !isNdrFetched) {
      this.props.fetchTrackingHandler(query).then(res => {
        // message.config({
        //   top: 300,
        //   duration: 400
        // });
        if (res.meta && res.meta.success) {
          const { tracking, user } = this.props;
          if (!tracking.valid && query.altUrl) {
            // pageRedirectionHandler({
            //   url: query.altUrl,
            //   waybill: query.waybill,
            //   enterpriseId: tracking.theme.enterprise_user_id
            // });
            window.location.href = isUrl(query.altUrl)
              ? query.altUrl
              : `https://${query.altUrl}`;
          }
          if ('shipments' in res.result) {
            return this.props.history.push(
              `${routePrefix ? `/${routePrefix}` : ''}/my-order${this.props
                .location.search + this.props.location.hash}`
            );
          }
          // edd configuration
          if (
            theme.meta_data &&
            !theme.meta_data.remove_edd &&
            !res.result.config.is_rvp
          ) {
            this.props.fetchEddHandler(query);
          }
          if (user.env !== 'development') {
            const { location } = this.props.history;
            ReactGA4.send({
              hitType: 'pageview',
              page: location.pathname + location.search,
              enterprise: user.enterprise,
              uber_status: tracking.latestStatus.code,
              waybill: tracking.waybill,
              cp_name: tracking.courier.actName,
              tracking_source: page.source || '',
              cp_id: tracking.courier.id,
              enterprise_id: theme.enterprise_user_id
            });

            ReactGA.set({ dimension1: user.enterprise });
            ReactGA.set({ dimension2: tracking.latestStatus.code });
            ReactGA.set({ dimension4: tracking.waybill });
            ReactGA.set({ dimension5: tracking.courier.actName });
            ReactGA.set({ dimension7: page.source || '' });
            ReactGA.set({ dimension8: tracking.courier.id });
            ReactGA.set({ dimension9: theme.enterprise_user_id });
            ReactGA.pageview(location.pathname + location.search);
          }
          if (res.meta && res.meta.success && res.result.config.is_rvp) {
            let route = '';
            if (
              reverseOrder.pickupFailed.indexOf(
                res.result[query.waybill].latest_status.clickpost_status_code
              ) > -1 &&
              res.result.config.is_pickup_form_visible
            ) {
              this.props
                .fetchNprOpsFeedHandler(tracking.trackingId, query.security_key)
                .then(res => {
                  if (res.meta && res.meta.success) {
                    this.props
                      .fetchNprCustomerFeedHandler(
                        tracking.trackingId,
                        query.security_key
                      )
                      .then(res => {
                        if (res.meta && res.meta.success) {
                          route = `${
                            routePrefix ? `/${routePrefix}` : ''
                          }/reverse/issue${this.props.location.search +
                            this.props.location.hash}`;
                          this.props.history.push(route);
                        }
                      });
                  }
                });
            } else {
              route = `${routePrefix ? `/${routePrefix}` : ''}/reverse${this
                .props.location.search + this.props.location.hash}`;
              this.props.history.push(route);
            }
          } else if (
            (tracking.config.isRating ||
              this.props.tracking?.theme?.config?.nps_experience ===
                'BRAND_EXPERIENCE') &&
            tracking.latestStatus.code === 8
          ) {
            this.props.fetchRatingHandler(tracking.trackingId);
          } else if (
            (tracking.order.isFailed || tracking.order.everFailed) &&
            tracking.config.isFailedDelivery &&
            !tracking.config.invalidSecurityCheck
          ) {
            // If current status is failed, it'll popup concern/issue form.
            this.props
              .fetchOpsFeedHandler(tracking.trackingId, tracking.config.user)
              .then(res => {
                if (res.meta && res.meta.success) {
                  this.props
                    .fetchCustomerFeedHandler(tracking, tracking.customer)
                    .then(res => {
                      if (
                        res.meta &&
                        res.meta.success &&
                        this.props.customerFeed &&
                        res.result.new_ndr
                      ) {
                        this.props.history.push(
                          `${routePrefix ? `/${routePrefix}` : ''}/issue${this
                            .props.location.search + this.props.location.hash}`
                        );
                      }
                    });
                }
              });
          }
          zipyIdentify(
            {
              reference_number: res.result.reference_number,
              awb: query.waybill ?? res.result.waybill,
              order_type: res.result.config.order_type
            },
            user
          );
        } else {
          if (res.meta && res.meta.isHome) {
            // toast.error(res.meta.message);
            // this.props.history.push(
            //   `/${routePrefix ? `${routePrefix}` : ''}?${
            //     query.app_only ? `&app_only=${query.app_only}` : ''
            //   }`
            // );
            this.setState({
              ...this.state,
              invalidShipment: true,
              message: res.meta.message
            });
          } else {
            const { counter } = this.state;
            this.setState({ counter: counter + 1 });
            if (res.meta && res.meta.status >= 400 && res.meta.message) {
              // toast.error(res.meta.message);
              // this.props.history.push(
              //   `/${routePrefix ? `${routePrefix}` : ''}?${
              //     query.app_only ? `&app_only=${query.app_only}` : ''
              //   }`
              // );
              if (query.altUrl) {
                // pageRedirectionHandler({
                //   url: query.altUrl,
                //   waybill: query.waybill,
                //   enterpriseId: this.props.tracking.theme.enterprise_user_id
                // });
                window.location.href = isUrl(query.altUrl)
                  ? query.altUrl
                  : `https://${query.altUrl}`;
              }
              this.setState({
                ...this.state,
                invalidShipment: true,
                message: res.meta.message
              });
            } else {
              // toast.success(res.message);
              this.setState({
                ...this.state,
                invalidShipment: true,
                message: res.meta?.message
              });
              if (this.state.counter < 2) {
                this.fetchTracking();
              }
            }
          }
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    const nextSearch = this.context.router.history.location.search;
    if (prevProps.location.search !== nextSearch) {
      // if(this.refs.searchInput){
      //   this.refs.searchInput.value = '';
      // }
      // this.setState({searchInput: '', appLink: ''});
      this.fetchTracking();
    }
  }

  closeShare = () => {
    let { user } = this.props;
    user = Object.assign(user, { shareOn: false });
    this.props.updateUserHandler(user);
  };

  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions() {
    const { user } = this.props;
    let characters = 0;
    const width = window.innerWidth;
    if (width < 321) {
      characters = 72;
    } else if (width < 376) {
      characters = 85;
    } else if (width < 415) {
      characters = 110;
    } else if (width < 769) {
      characters = 120;
    } else if (width < 1025) {
      characters = 120;
    } else if (width > 1024) {
      characters = 180;
    }
    this.setState({ width: width, characters });
    this.props.updateUserHandler(
      Object.assign(user, { device: width > 768 ? 'desktop' : 'mobile' })
    );
    this.calculateBannerHeight();
  }

  scrolling() {
    let { showAppLink } = this.state;
    const { appLink, hideLink } = this.props.user;
    if (window.pageYOffset > 50) {
      if (!showAppLink && appLink && !hideLink) {
        this.setState({ showAppLink: true });
      }
    }
  }

  calculateBannerHeight = () => {
    const { user } = this.props;
    if (
      this.refs.adsCard &&
      this.refs.adsCard.refs.ads &&
      this.refs.adsCard.refs.ads.clientHeight
    ) {
      let bannerHeight = this.refs.adsCard.refs.ads.clientHeight || 0;
      if (bannerHeight && window.innerWidth > 768) {
        bannerHeight = bannerHeight - 122;
        this.props.updateUserHandler(Object.assign(user, { bannerHeight }));
      }
    }
  };

  /**
   * Add event listener
   */
  componentDidMount() {
    this.fetchTracking();
    // window.addEventListener('resize', this.updateDimensions.bind(this));
    window.addEventListener('scroll', this.scrolling.bind(this));
    // Detecting device information
    this.updateDimensions();
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    // window.removeEventListener('resize', this.updateDimensions.bind(this));
    window.removeEventListener('scroll', this.scrolling.bind(this));
  }

  closeAppPopUp() {
    this.setState({ showAppLink: false, appLink: '' });
    const { user } = this.props;
    // user = Object.assign(user, {hideLink: true});
    this.props.updateUserHandler(Object.assign(user, { hideLink: true }));
  }

  getBlockComponent(component) {
    const { theme } = this.props.tracking;
    const { meta_data } = theme;
    let adsEnabled = null;
    let productsEnabled = false;

    const { showRating } = this.props.tracking.config;

    if (
      meta_data.recommendedProducts &&
      meta_data.recommendedProducts.enabled
    ) {
      productsEnabled = meta_data.recommendedProducts.enabled;
    }
    if (meta_data && meta_data.ads_enabled) {
      adsEnabled = true;
      if (meta_data.banners && meta_data.banners.length > 0) {
        const filtered = meta_data.banners.filter(
          item => item.type === 'right'
        );
        if (filtered.length === 0) {
          adsEnabled = false;
        }
      }
      // Unnecessary statement found
      // } else if (
      //   meta_data &&
      //   meta_data.ads_enabled === undefined &&
      //   (meta_data.banners && meta_data.banners.length > 0)
      // ) {
      //   adsEnabled = true;
    } else {
      adsEnabled = false;
    }
    const { udfKeys } = this.props.tracking.theme;
    switch (component.block) {
      case 'orderInfo':
        return (
          <div key={0} className={`col-md-24 col-xs-24 cm-left`}>
            <MetaCard
              key={0}
              trackingInfo={this.props.tracking}
              isFetching={this.props.tracking.isFetching}
              characters={this.state.characters}
              cardsInMobile={component.inMobile}
              openShare={this.openShare}
              productsEnabled={productsEnabled}
            />
            {udfKeys?.defaultTrackingMessage ? (
              <SpecialMessage message={udfKeys.defaultTrackingMessage} />
            ) : null}
          </div>
        );
      case 'rating':
        if (
          this.props.tracking.config.invalidSecurityCheck ||
          this.props.rating.isFetching ||
          !showRating ||
          udfKeys?.noNPS ||
          this.props.tracking?.latestStatus?.code !== 8
        ) {
          return null;
        }
        return (
          <div key={1} className="col-xs-24 mrg-top-20 xs-mrg-top-10">
            {this.props?.tracking?.theme?.config?.nps_experience ===
            'BRAND_EXPERIENCE' ? (
              <BrandRating />
            ) : (
              <RatingCard
                key={1}
                trackingInfo={this.props.tracking}
                isFetching={this.props.tracking.isFetching}
                rating={this.props.rating}
                handleRating={this.handleRating}
                changeRating={this.changeRating}
              />
            )}
          </div>
        );
      case 'timeline':
        return (
          <div
            key={2}
            className={`${
              adsEnabled === false ? 'col-md-24' : 'col-md-15'
            } col-xs-24 mrg-top-20 xs-mrg-top-10`}
          >
            <Timeline
              key={2}
              tracking={this.props.tracking}
              isFetching={this.props.tracking.isFetching}
              height={this.props.user.bannerHeight || 75}
              productsEnabled={productsEnabled}
              fullHeight={udfKeys?.fullTimeline}
            />
          </div>
        );
      case 'ads':
        if (adsEnabled === false) {
          return null;
        }

        return (
          <Banner
            ref="adsCard"
            key={3}
            isFetching={this.props.tracking.isFetching}
            banners={this.props.tracking.theme.meta_data.banners}
            handleImageLoad={this.calculateBannerHeight}
            subdomain={this.props.user.subdomain}
            lang={this.props.user.lang}
          />
        );
      case 'bottom':
        if (!productsEnabled) {
          return null;
        }
        return (
          <RecommendProducts
            key={4}
            banners={this.props.tracking.theme.meta_data.banners}
            products={this.props.tracking.theme.meta_data.recommendedProducts}
            device={this.props.user.device}
            subdomain={this.props.user.subdomain}
            lang={this.props.user.lang}
          />
        );
      // case 'concernCard':
      //   return <RequestCard trackingInfo={this.state.trackingInfo} isFetching={this.state.isFetching} />;
      case 'payment':
        return (
          <Payment wrapperClassName="col-md-24 col-xs-24 mrg-top-20 xs-mrg-top-10" />
        );
      default:
        return null;
    }
  }

  gaEvents(e, eventName, label, action) {
    if (action) {
      ga4ClickEvent({ category: eventName, action, label, props: this.props });
      ReactGA.event({
        category: eventName,
        label: label,
        action: action
      });
    }
  }

  render() {
    // const { trackingInfo, isFetching, searchInput, action, appLink } = this.state;
    const { tracking, user, page } = this.props;
    const enterpriseProfile = tracking.theme.meta_data;
    const enterpriseLayout = layout[user.subdomain] || layout.default;
    const hostUrl = user.userHostname + page.url;
    const pageLayout =
      enterpriseLayout[
        tracking.order.timeline[
          tracking?.order?.reached && tracking.order.reached !== -1
            ? tracking.order.reached
            : 0
        ]?.status
      ];
    return (
      <Fragment>
        {tracking.isFetching ? (
          <PageSpinner subdomain={user.subdomain} />
        ) : (
          <Fragment>
            <div className={`headerstrip hide`}>
              <p>
                Thank you for your input. We are coordinating with{' '}
                <strong>{tracking.courier.name}</strong> to get the shipment
                delivered to you at the earliest.
                <span
                  className="icon-close cursor-pointer"
                  onClick={this.toggleTopNotification}
                />
              </p>
            </div>
            <div
              className={`container-fluid pd-top-40 xs-pd-top-10 pd-btm-40 xs-pd-btm-10 main-container 
              ${user.subdomain == '6thstreet' ? 'sixthstreet' : ''} 
              ${user.subdomain == '6thstreet-test' ? 'sixthstreet-test' : ''}
              ${user.subdomain == '21kschool' ? 'twentyonekschool' : ''}
              ${
                user.subdomain == '1mg' || user.subdomain == '1mg-test'
                  ? 'onemg'
                  : user.subdomain
              }`}
            >
              {this.state.invalidShipment ? (
                <NotFoundCard
                  message={this.state.message}
                  subMessage={this.state.subMessage}
                />
              ) : null}
              {tracking?.order?.timeline.length > 0 ? (
                <div className="row col-gutter-130">
                  {pageLayout
                    ? (Array.isArray(pageLayout)
                        ? pageLayout
                        : pageLayout(this.props.user.device === 'mobile')
                      ).map(item => this.getBlockComponent(item))
                    : null}
                </div>
              ) : null}
            </div>
          </Fragment>
        )}
        <AppPopUp user={user} tracking={tracking} page={page} />
        {this.state.showAppLink &&
        (user.subdomain === 'hopscotch' ||
          user.subdomain === 'hopscotch-test') ? (
          <div className="row app-link visible-xss hide">
            <div className="col-xs-5 pd-0">
              <a
                href={user.appLink}
                target="_blank"
                onClick={e => this.gaEvents(e, 'App Install', user.device)}
                rel="noopener noreferrer"
              >
                <img src={user.icon} width="60" alt="app icon" />
              </a>
            </div>
            <div className="col-xs-12 mrg-top-15">
              <p className="mrg-btm-10">
                Get the app now for exciting new offers!
              </p>
              {/* <p>Switch to app for better experience</p> */}
            </div>
            <div className="col-xs-7 pd-0 text-right">
              <span className="icon-close" onClick={this.closeAppPopUp} />
              <a
                className="button"
                href={user.appLink}
                target="_blank"
                rel="noopener noreferrer"
                onClick={e =>
                  this.gaEvents(e, 'App Install', user.device, user.appLink)
                }
              >
                INSTALL
              </a>
            </div>
          </div>
        ) : null}

        {tracking.isFetching ? null : (
          <div
            className={`container-fluid black-overlay visible-xs ${
              user.shareOn ? 'visible-xs' : 'hidden-xs'
            }`}
            onClick={this.closeShare}
          >
            <div className="row">
              <div
                className="col-xs-24 share-container"
                onClick={e => e.stopPropagation()}
              >
                <span
                  className="icon-close cursor-pointer"
                  onClick={this.closeShare}
                />
                <p className="header">Share with friends</p>
                <ul>
                  <li
                    onClick={e =>
                      this.gaEvents(e, 'Social', 'Whatsapp', 'product share')
                    }
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://wa.me/?text=Track my order @%20${hostUrl}&ref=social_wa_clickpost`}
                    >
                      <span className="active icon-whatsapp" /> WhatsApp
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={e =>
                        this.gaEvents(e, 'Social', 'Facebook', 'product share')
                      }
                      href={`https://www.facebook.com/dialog/share?app_id=1932988240339501&display=popup&href=${hostUrl}&redirect_uri=${encodeURIComponent(
                        hostUrl
                      )}&hashtag=${
                        enterpriseProfile.hashtag
                      }&quote=Track my order`}
                    >
                      <span className="active icon-fb" /> Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      target="_self"
                      onClick={e =>
                        this.gaEvents(e, 'Social', 'Twitter', 'product share')
                      }
                      href={`https://twitter.com/intent/tweet?original_referer=${hostUrl}&ref=social_tw_clickpost&text=Track my order &url=${hostUrl}&ref=social_tw_clickpost&via=${enterpriseProfile.twitterHandle}`}
                    >
                      <span className="active icon-twitter" /> Twitter
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

Tracking.contextTypes = {
  router: PropTypes.object.isRequired
};

Tracking.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  rating: PropTypes.object,
  page: PropTypes.object,
  updateUserHandler: PropTypes.func,
  location: PropTypes.object,
  setPageHandler: PropTypes.func,
  fetchTrackingHandler: PropTypes.func,
  fetchEddHandler: PropTypes.func,
  fetchRatingHandler: PropTypes.func,
  fetchOpsFeedHandler: PropTypes.func,
  fetchCustomerFeedHandler: PropTypes.func,
  history: PropTypes.object,
  customerFeed: PropTypes.object,
  fetchNprOpsFeedHandler: PropTypes.func,
  fetchNprCustomerFeedHandler: PropTypes.func
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  user: state.user,
  rating: state.rating,
  page: state.page,
  customerFeed: state.customerFeed
});

const mapDispatchToProps = dispatch => ({
  updateUserHandler: user => dispatch(updateUserHandler(user)),

  setPageHandler: query => dispatch(pageHandler(query)),
  fetchTrackingHandler: query => dispatch(trackingHandler(query)),
  fetchEddHandler: query => dispatch(eddHandler(query)),
  fetchRatingHandler: trackingId => dispatch(ratingHandler(trackingId)),
  fetchOpsFeedHandler: (trackingId, user) =>
    dispatch(opsFeedHandler(trackingId, user)),
  fetchCustomerFeedHandler: (tracking, customerInfo) =>
    dispatch(customerFeedHandler(tracking, customerInfo)),
  fetchNprOpsFeedHandler: (trackingId, security_key) =>
    dispatch(nprOpsFeedHandler(trackingId, security_key)),
  fetchNprCustomerFeedHandler: (tracking, customerInfo) =>
    dispatch(nprCustomerFeedHandler(tracking, customerInfo))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Tracking)
);
