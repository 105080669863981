import React from 'react';
import PropTypes from 'prop-types';
import NotFoundImage from './NotFoundImage';

const NotFoundCard = ({ message, subMessage, noMessage = false }) => {
  return (
    <div className="row">
      <div className="fixed-center col-md-18 col-xs-22 col-md-offset-3 col-xs-offset-1 xs-mrg-top-10">
        <div className="not-found-content">
          {/* <img src="/images/not-found.svg" className="mrg-btm-20 not-found" alt="" /> */}
          {/* <div className=""> */}
          <NotFoundImage />
          {/* </div> */}
          {!noMessage && (
            <>
              <p className="message mrg-btm-10 fs-20">{message}</p>
              <p className="footer fs-14">{subMessage}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

NotFoundCard.propTypes = {
  message: PropTypes.string,
  subMessage: PropTypes.string,
  noMessage: PropTypes.bool
};

export default NotFoundCard;
